export enum ShipmentViewLabels {
  acceptActionButtonLogDialog = 'Aceptar',
  additionalConfSuccess = 'El transporte ha sido asignado',
  allOrdersDeleted = 'Todas las órdenes han sido eliminadas',
  allOrdersSaved = 'Todas las órdenes han sido guardadas',
  assignationErrorMessage = 'No se pudo actualizar el embarque',
  cancel = 'Cancelar',
  cancelActionMessage = 'eliminar',
  cancelActionMessageDone = 'eliminado',
  carrier = 'Transportista',
  confirm = 'Confirmar',
  confirmActionMessage = 'confirmar',
  confirmActionMessageDone = 'confirmado',
  confirmDialogQuestion = '¿Estás seguro que deseas confirmar el embarque?',
  confirmDialogResumeOne = 'Has seleccionado ',
  confirmDialogResumeTwo = ' para ser confirmado',
  confirmDialogTitle = 'Confirmación de Embarques',
  delete = 'Eliminar',
  deleteDiaglogResume = 'Toda la configuración e información del embarque seleccionado se eliminará y no podrá usarse',
  deleteDialogQuestion = '¿Estás seguro que deseas eliminar el embarque?',
  deleteDialogResumeOne = 'Has seleccionado ',
  deleteDialogResumeTwo = ' para ser eliminado, las órdenes asociadas pasarán a un estatus "Sin asignar". Esta acción no podrá deshacerse',
  deleteDialogTitle = 'Eliminación de Embarque ',
  deleteErrorMessage = 'El embarque no puede ser eliminado',
  deleteInTransitErrorMessage = 'El embarque se encuentra en tránsito, no puede eliminarse',
  deleteOrderQuestion = '¿Estás seguro de que deseas eliminar todas las órdenes asociadas al embarque?',
  deleteOrderResume = 'Las órdenes de este embarque pueden ser eliminadas o ser guardadas para utilizarse posteriormente',
  deleteOrderTitle = 'Eliminación de Órdenes',
  dialogAllErrorCancel = 'Cerrar',
  dialogAllErrorClose = 'Descargar',
  dialogAllErrorImage = '../../../assets/2sheets-eliminate.svg',
  dialogAllErrorQuestion = 'Verifica la información del embarque e inténtalo de nuevo',
  dialogAllErrorResume = 'Se descargará a tu equipo de cómputo un archivo con el resumen de los errores encontrados.',
  dialogAllErrorTitle = 'Existen errores en los datos del embarque',
  dialogConfirmation = 'Si',
  dialogRejection = 'No',
  downloadSelectPlaceHolder = 'Selecciona un tipo de descarga',
  edit = 'Editar',
  errorChangingDestinationShipment = 'Error al asignar el destino al embarque',
  errorMessageToaster = 'Error al actualizar la orden: ',
  errorShipmentCreate = 'Error al confirmar el embarque',
  errorShipmentFTP = 'Error al enviar la información al FTP',
  focusText = 'Esta acción no podrá deshacerse',
  getAccountsError = 'No se encontraron cuentas de este embarcador, verifica la información',
  goBack = 'Regresar',
  missingInvoiceSaleAmountError = 'El embarque no se puede confirmar ya que contiene órdenes sin Factura y Monto de venta.',
  notOrdersAssociated = 'No se pudo actualizar el embarque, debido a que no hay órdenes asociadas',
  onTransit = 'En Transito',
  processingMessageToaster = 'Un momento. Se está procesando la solicitud',
  save = 'Guardar',
  shipmentAlreadyConfirmed =  'El embarque ya fue confirmado.',
  shipmentChargeTypeEdited = 'El tipo de cobro del embarque ha sido actualizado',
  shipmentDetailTitle = 'Detalle de Embarque',
  shipmentLogDialogTitle = 'Modificaciones del Embarque ',
  shipmentViewComponentRoute = '/shipment/shipment-view',
  shipmentViewDash = '/',
  successShipmentFTP = 'La información del transporte ha sido enviada correctamente',
  taxStampDownloadTitle = 'Descargar archivos de carta porte',
  taxStampSendError = 'No se pudo enviar la información del embarque al sistema de timbrado.',
  taxStampSendError400 = ' Por favor, verifique que todos los datos del embarque son correctos',
  taxStampSendErrorTrasportist = ' Por favor, verifique que todos los datos del embarque son correctos. No se pudo descargar el formato.',
  taxStampSucces = 'La información del embarque ha sido enviada al sistema de timbrado.',
  taxStampSuccesTrasport = 'El formato al transportista ha sido generado exitosamente.',
  toastErrorTaxStamp = 'Error al consultar con el servicio de timbrado.',
  toastInfoTaxStampNotProcessed = 'El proveedor de timbrado no ha finalizado con el procesamiento de la información. Inténtalo más tarde.',
  toastSuccessDownloadFile = 'Tu archivo ha sido descargado correctamente',
  toastSuccessDownloadFiles = 'Tu archivos han sido descargados correctamente',
  toastWarningConfirmStatus = 'El embarque no puede ser confirmado',
  toastWarningEditShipment = 'El embarque no puede ser editado',
  toastWarningTaxStampErrors = 'El proveedor de timbrado encontró errores al procesar la información.',
  paymentTypeTitle = 'Tipo de pago de embarque ',
  unavailableShipmentMessage = 'No hay embarques disponibles',
  updateErrorMessageToasterOne = 'Error al ',
  updateErrorMessageToasterThree = ', intente nuevamente',
  updateErrorMessageToasterTwo = 'el embarque ',
  updateSuccessMessageToaster = 'Embarque ',
  warningConfirmPortageQuestion = '¿Deseas continuar con la confirmación del embarque o editar las bodegas de porteo?',
  warningConfirmPortageSummary = 'El embarque es de tipo Porteo o Porteo con reparto y al menos una de las órdenes '
  + 'no tiene bodega planeada.',
  warningConfirmPortageTitle = 'Advertencia de confirmación de embarque',
  warningErrorBeginDate = 'La fecha de inicio debe ser menor a la fecha de fin',
  warningErrorDateRange = 'La fecha de inicio y fecha de fin son requeridos para la búsqueda',
  warningErrorEndDate = 'La fecha de fin debe ser mayor a la fecha de inicio',
  warningMaximumPeriodSearch = 'El periodo de búsqueda puede ser de hasta un máximo de ',
  warningMaximumPeriodSearchComplement = ' días',
  warningPeriodSearch = 'El periodo de búsqueda puede ser hasta máximo un mes',
  warningTransportMessage = 'El transporte ya fue asignado'
}

export enum ShipmentViewTags {
  assigned = 'Asignado',
  assignedTransport = 'Transporte asignado',
  assignementTooltip = 'Asignar transporte',
  buttonTitleExportExcel = 'Exportar a Excel',
  cardTitle = 'Filtros de Búsqueda',
  carrier = 'Línea de Transporte',
  chargeTypeUnavailable = 'Solo a embarques Asignados se les puede indicar el tipo de cobro.',
  confirmed = 'Confirmado',
  confirmShipmentTooltip = 'Confirmar embarque',
  confirmTooltip = 'Confirmar',
  confirmVehicleTooltip = 'Confirmar transporte',
  createShipmentTooltip = 'Crear Embarque',
  creationDate = 'Fecha de creación',
  dateRange = 'Rango de fechas',
  deleteShipmentTooltip = 'Eliminar embarque',
  destination = 'Nombre de Destino',
  doesNotApply = 'N/A',
  downloadPDF = 'Descargar PDF',
  downloadTaxStampTooltip = 'Descargar carta porte',
  driver = 'Operador',
  editTooltip = 'Editar',
  filterAccount = 'Cuenta',
  filterCarrier = 'Línea de transporte',
  filterDestination = 'Destino',
  filterSearchButton = 'Buscar',
  filterSelectBeginDate = 'Fecha de inicio',
  filterSelected = 'Selecciona',
  filterSelectEndDate = 'Fecha de fin',
  filterShipmentClass = 'Clase de viaje',
  filterWarehouse = 'Bodega(s)',
  functions = 'Acciones',
  internalReference = 'Referencia interna',
  loadShipmentsTooltip = 'Cargar Embarques',
  modified = 'Modificado',
  noData = 'Sin información',
  orders = 'Número de Órdenes',
  origin = 'Bodega Origen',
  plannedStatus =  'Planeado',
  planningDate = 'Fecha de planeación',
  roadnetIdentifier = 'Identificador de Roadnet',
  satLocationNotFound = 'No se ha encontrado una ubicación válida con el código postal proporcionado. Verifique la información.',
  searchInTable = 'Busca en la tabla',
  sendToTendering = 'Enviar a tendering',
  shipmentClass = 'Clase de viaje',
  shipmentFolio = 'Folio de Embarque',
  shipmentRequest = 'Solicitud',
  status = 'Estatus',
  submenuTooltip = 'Ver más',
  suggestedTransport = 'Transporte sugerido',
  taxStampAlreadySent = 'El embarque fue enviado al servidor de timbrado',
  taxStampFlagSent = 'Sent to provider',
  taxStampNotAllowed = 'El timbrado solo está disponible para embarques con estatus confirmado',
  taxStampShipperNotAvailable = 'Funcionalidad no disponible. Contacta a tu administrador',
  taxStampTooltip = 'Timbrar',
  taxStampTooltipTransport = 'Formato Transportista',
  titlePage = 'Embarques',
  tripType = 'Tipo de viaje',
  typeOfChargeTooltip = 'Tipo de cobro',
  vehicle = 'Placas',
  viewOrdersTooltip = 'Ver órdenes',
  viewPlanTooltip = 'Ver plan de carga'
}

export enum StampDataErrors {
  atLeastOneSku = ' debe tener al menos un SKU.\n',
  carrier = 'La línea de transporte ',
  customRegime = 'Regimen aduanero es requerido. \n',
  destination = 'El destino ',
  destinationPoleLocation = 'Ubicación polo destino es requerido. \n',
  doesNotHaveRfc = ' no tiene RFC.\n',
  doesNotHaveTaxRegime = ' no tiene régimen fiscal.\n',
  doesNotHaveType = ' no tiene tipo asociado.\n',
  driverNotFound = 'El operador no pudo ser encontrado.\n',
  driverRfc = 'El operador no tiene RFC.\n',
  freightCostNotFound = 'No se encontró una tarifa de flete.\n',
  inOrder = '" de la orden ',
  order = 'La orden ',
  ordersNotFound = 'Una o más ordenes del embarque no pudieron ser encontradas.\n',
  originPoleLocation = 'Ubicación polo origen es requerido. \n',
  prodServ = ' no tiene Clave de Producto o Servicio.\n',
  product = 'El producto "',
  taxResidence = 'Residencia fiscal es requerido. \n',
  unit = ' no tiene una descripción de unidad de medida.\n',
  warehouseRfc = 'La bodega no tiene RFC.\n'
}

export enum StampDataCofeprisErros {
  activeIngredientName = ' no tiene Nombre ingrediente activo. \n',
  activeSubstanceGeneric = ' no tiene Denominación genérica. \n',
  activeIngredientManufacturerCountryName = ' no tiene Datos del formulador. \n',
  activeIngredientMaquilaCountryName = ' no tiene Datos del maquilador. \n',
  authorizedUse = ' no tiene Uso autorizado. \n',
  chemicalName = ' no tiene Nombre químico. \n',
  cofeprisSector = ' no tiene sector COFEPRIS. \n',
  cofeprisProduct = ' no tiene el indicador si se trata de un producto de COFEPRIS. \n',
  cofeprisPesticideNumber = ' no tiene Número de registro sanitario plaguicidas. \n',
  dangerousMaterial = ' no tiene el indicador de material peligroso. \n',
  dangerousMaterialKey = ' no tiene Clave del material peligroso.	\n',
  expirationDate = ' no tiene Fecha de caducidad. \n',
  healthRegisterFolio = ' no tiene Registro sanitario. \n',
  importLicense = ' no tiene Permiso de importación. \n',
  importVUCEMFolio = ' no tiene Folio VUCEM. \n',
  importCompany = ' no tiene Empresa importadora. \n',
  lot = ' no tiene Lote. \n',
  manufacturer = ' no tiene Fabricante. \n',
  matterType = ' no tiene Tipo de material. \n',
  numCAS = ' no tiene Número CAS. \n',
  packaging = ' no tiene Clave de embalaje del producto. \n',
  packagingDescription = ' no tiene Descripción de embalaje del producto. \n',
  pharmaceuticalForm = ' no tiene Forma farmacéutica. \n',
  pesticideManufacturerCountryName = ' no tiene Datos del fabricante. \n',
  prodServKey = ' no tiene clave producto servicio. \n',
  trademarkMedication = ' no tiene Denominación distintiva. \n',
  trasportSpecialConditions = ' no tiene Condiciones especiales de transporte. \n',
  unitKey = ' no tiene la unidad de medida. \n',
  weightInKg = ' no tiene peso. \n'
}

export enum StampVehicleValidation {
  defaultValidationString = 'Valor por defecto',
  insuranceCarrierNameNotFound = ' no tiene aseguradora.\n',
  insurancePolicyNumberNotFound = ' no tiene número de póliza de seguro.\n',
  sctPermitNumberNotFound = ' no tiene número de permiso SCT.\n',
  sctPermitTypeNotFound = ' no tiene Tipo de permiso SCT.\n',
  vehicle = 'El vehículo ',
  vehicleConfigurationNotFound = ' no tiene configuración vehicular.\n'
}
