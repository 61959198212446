export const MENU_CONSTANTS = [
  { value: 'Administrador de Notificaciones', label: 'notificationManager' },
  { value: 'Bitácora de Solicitud', label: 'shipmentRequestLogs' },
  { value: 'Bodegas', label: 'warehouses' },
  { value: 'Cambios de estatus', label: 'statusChanges' },
  { value: 'Carga de Solicitudes con Órdenes', label: 'loadingShipmentRequestWithOrder' },
  { value: 'Carga de Solicitudes Turísticas', label: 'loadingShipmentTouristRequests' },
  { value: 'Carga de Ubicaciones por Excel', label: 'locationLoadByExcel' },
  { value: 'Carga Masiva de Órdenes', label: 'ordersMassiveLoad' },
  { value: 'Carga Masiva de Tarifas', label: 'freightRateMassiveLoad' },
  { value: 'Cargos adicionales', label: 'additionalCharges' },
  { value: 'Catálogos', label: 'catalogs' },
  { label: 'freightRate', value: 'Catálogo de Tarifas de flete' },
  { value: 'Configuración', label: 'settings' },
  { value: 'Confirmación de entrega', label: 'confirmDeliveryStatus' },
  { value: 'Consultar detalle de evidencias', label: 'evidenceDetailView' },
  { value: 'Consultar Folio de Liberación de Evidencia', label: 'evidenceReleaseFolio' },
  { value: 'Consultar Folio', label: 'evidenceReleaseFolioView' },
  { value: 'Control de Accesos de Unidades', label: 'vehicleAccessControl' },
  { value: 'Control de Evidencias', label: 'evidenceRelease' },
  { label: 'documentaryRegisterView', value: 'Registro documental' },
  { label: 'evidenceControlView', value: 'Nuevo Control de Evidencias' },
  { label: 'evidenceReleaseView', value: 'Liberación de órdenes' },
  { value: 'Control Tower Axalta', label: 'controlTowerAxalta' },
  { value: 'Control Tower Cuervo', label: 'controlTowerCuervo' },
  { value: 'Control Tower Dicka', label: 'controlTowerDicka' },
  { value: 'Control Tower E2E', label: 'controlTowerE2E' },
  { value: 'Control Tower Hasbro', label: 'controlTowerHasbro' },
  { value: 'Control Tower LocalHub', label: 'controlTowerLocalHub' },
  { value: 'Control Tower Logyt', label: 'controlTowerLogyt' },
  { value: 'Control Tower Medistik', label: 'controlTowerMedistik' },
  { value: 'Control Tower Pioneer', label: 'controlTowerPioneer' },
  { value: 'Control Tower Rivasa', label: 'controlTowerRivasa' },
  { value: 'Control Tower Sanfer', label: 'controlTowerSanfer' },
  { value: 'Control Tower SubZero', label: 'controlTowerSubZero' },
  { value: 'Control Tower Symrise', label: 'controlTowerSymrise' },
  { value: 'Control Tower Ucín Médica', label: 'controlTowerUcinMedica' },
  { value: 'Control Tower', label: 'controlTower' },
  { value: 'Control Vehicular de Entrada', label: 'entryVehicleControl' },
  { value: 'Control Vehicular', label: 'vehicleControl' },
  { value: 'Costeo', label: 'cost' },
  { value: 'Crear folio de liberación de evidencias', label: 'createEvidenceReleaseFolio' },
  { value: 'Creación masiva de órdenes', label: 'orderBulkLoad' },
  { value: 'Crear Orden', label: 'createOrder' },
  { value: 'Crear Solicitud de Transporte', label: 'createShipmentRequest' },
  { value: 'Cuentas', label: 'accounts' },
  { value: 'Descarga de Evidencias', label: 'evidenceDownload' },
  { value: 'Detalle de Evidencias', label: 'evidenceDetailView' },
  { value: 'División de orden', label: 'orderSplit' },
  { value: 'Embarcador', label: 'shipper' },
  { value: 'Embarcadores', label: 'shippers' },
  { value: 'Embarques', label: 'shipments' },
  { value: 'Entrada de Mercancía', label: 'goodsReceipt' },
  { value: 'Esquemas de Facturación', label: 'billingSchemes' },
  { value: 'Eventos de viaje', label: 'tripEvents' },
  { value: 'Evidencias', label: 'evidences' },
  { value: 'Folio de evidencias', label: 'customEvidenceReleaseFolio' },
  { value: 'Folio de Liberación de Evidencias', label: 'evidenceReleaseFolio' },
  { value: 'General', label: 'configuration' },
  { value: 'Generar guías de paquetera', label: 'courierWaybill' },
  { value: 'Grupos de tendering', label: 'carrierGroups' },
  { label: 'remissionGuidesLabels', value: 'Guías de remisión' },
  { value: 'Habilitar Reenvío de Timbrado', label: 'enableTaxStampForwarding' },
  { value: 'Histórico de Temperaturas', label: 'temperatureHistory' },
  { value: 'Histórico de Viajes', label: 'travelsHistory' },
  { value: 'Importación de Líneas de transporte', label: 'importationOfCarries' },
  { value: 'Importación de Solicitudes con Órdenes', label: 'importationOfShipmentRequestWithOrder' },
  { value: 'Importación de Vehículos', label: 'importationOfVehicles' },
  { value: 'Importación de Viajes para Hieleras', label: 'travelForCoolersMassiveLoad' },
  { value: 'Importar embarques de Roadnet', label: 'roadnetShipmentImportation' },
  { value: 'Impresión de etiquetas de paquetera', label: 'courierOrderLabelPrinting' },
  { value: 'Impresión de etiquetas', label: 'labelOrderPrinting' },
  { value: 'Inicio', label: 'home' },
  { value: 'Líneas de Transporte', label: 'carriers' },
  { value: 'Motivos de Incidencia', label: 'incidenceReasonCatalog' },
  { value: 'Motivos de Incidencia/Evidencia', label: 'incidenceEvidenceReasonsView' },
  { value: 'Motivos de Rechazo', label: 'rejectionReasonCatalog' },
  { value: 'Motivos', label: 'reasons' },
  { value: 'Movimientos masivos', label: 'massiveMovements' },
  { value: 'Operadores', label: 'drivers' },
  { value: 'Órdenes', label: 'orders' },
  { value: 'Pesos y volúmenes de forma masiva', label: 'weightsAndVolumesMassiveLoad' },
  { value: 'Pesos y volúmenes', label: 'weightsAndVolumes' },
  { value: 'Planeación con Roadnet', label: 'roadnetOrderRouting' },
  { value: 'Planeación de Embarque', label: 'shipmentPlanning' },
  { value: 'Productos', label: 'products' },
  { value: 'Propuesta de factura', label: 'invoiceProporsal' },
  { value: 'Proveedores', label: 'suppliers' },
  { value: 'Provisiones', label: 'provisions' },
  { value: 'Recepción de Órdenes', label: 'orderReception' },
  { value: 'Receptores', label: 'receivers' },
  { value: 'Recolecciones', label: 'shipmentRequestCollection' },
  { value: 'Registro de Facturas a Propuesta de Factura', label: 'invoicesRecordToInvoiceProposal' },
  { value: 'Registro de facturas a sábanas', label: 'registerFreightInvoices' },
  { value: 'Remolques', label: 'trailers' },
  { value: 'Reporte de Costeo de Embarques', label: 'freightCostReport' },
  { value: 'Reporte de Eficiencia de Operación', label: 'operatingEfficiencyReport' },
  { value: 'Reporte de Estatus General de Órdenes', label: 'generalOrderStatusReport' },
  { value: 'Reporte de evidencias', label: 'evidencesReport' },
  { value: 'Reporte de Incidencias de Rechazo', label: 'incidenceReport' },
  { value: 'Reporte de Pendiente de Evidencias', label: 'pendingEvidencesReport' },
  { value: 'Reporte de Pendientes de Costeo', label: 'pendingFreightCostReport' },
  { value: 'Reporte de sábanas de costeo', label: 'billingSheetsReport' },
  { value: 'Reporte en Tránsito', label: 'onDeliveryReport' },
  { value: 'Reporte Maestro Costeo', label: 'masterCostingReport' },
  { value: 'Reporte Pendientes de Embarque', label: 'shipmentPendingReport' },
  { value: 'Reportes', label: 'reports' },
  { value: 'Revertir Embarques', label: 'revertShipment' },
  { value: 'Revisión de Cargos', label: 'costReview' },
  { value: 'Revisión de cobro a clientes', label: 'reviewCustomerBilling' },
  { value: 'Sábana de costos de venta', label: 'salesSheet' },
  { value: 'Revisión de pago a proveedores', label: 'reviewPaymentSupplier' },
  { value: 'Roles', label: 'roles' },
  { value: 'Catálogo de Tarifas de venta', label: 'salesRate' },
  { value: 'Carga no ejecutada', label: 'LoadNotExecuted' },
  { value: 'Sábana de Costeo', label: 'billingSheet' },
  { value: 'Seguridad', label: 'security' },
  { value: 'Solicitudes Clientes', label: 'clientsShipmentRequest' },
  { value: 'Solicitudes Embarcador', label: 'shipperRequests' },
  { value: 'Solicitudes Transportista', label: 'carrierShipmentRequest' },
  { value: 'Solicitudes', label: 'shipmentRequest' },
  { value: 'Soporte', label: 'support' },
  { value: 'Tarifas de Licitación de transporte', label: 'transportationTenderRates' },
  { value: 'Tipos de Paquetes', label: 'packageTypes' },
  { value: 'Tipos de Servicios', label: 'serviceTypes' },
  { value: 'Tipos de Vehículos', label: 'vehicleTypes' },
  { value: 'Tracking', label: 'tracking' },
  { value: 'Ubicaciones Embarcador', label: 'shipperLocation' },
  { value: 'Ubicaciones', label: 'locations' },
  { value: 'Usuarios', label: 'userView' },
  { value: 'Validación de cargos', label: 'chargesValidation' },
  { value: 'Vehículos', label: 'vehicles' },
  { value: 'Ver Embarques', label: 'shipmentView' },
  { value: 'Ver Órdenes', label: 'ordersView' }
];
