import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { DialogChargeTypeLabels, IDialogChargeTypeLabels, IGeneralData } from './dialog-charge-type-labels';
import { DialogStandardFocus } from './../../../enums/dialog-stantdard-focus';

const PAYMENT_TYPE = 'paymentType';

/**
 * @description Component that represents a dialog for selecting the payment type.
 */
@Component({
  selector: 'app-dialog-standard',
  styleUrls: ['./dialog-charge-type.component.scss', '../../../app.component.scss'],
  templateUrl: './dialog-charge-type.component.html'
})
export class DialogChargeTypeComponent {
  public isSaveDisabled: boolean;
  public labels: IDialogChargeTypeLabels;
  public paymentTypeForm: UntypedFormGroup;
  public selected: string;
  public tenantId: string;

  /**
   * @description Constructor to initialize the dialog component.
   * @param {UntypedFormBuilder} builder - Form builder service for creating the form group.
   * @param {IGeneralData} data - Data injected into the dialog.
   * @param {MatDialogRef<DialogChargeTypeComponent>} dialogRef - Reference to the dialog, allowing for closing it with a result.
   */
  constructor(
    private readonly builder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: IGeneralData,
    public dialogRef: MatDialogRef<DialogChargeTypeComponent>
  ) {
    this.labels = DialogChargeTypeLabels;

    if (data.shipment.paymentType) {
      this.selected = data.shipment.paymentType;
    } else {
      this.selected = this.labels.paymentByTypeValue;
    }
    this.initChargeTypeForm();
    this.onChangeDetected();
  }

  /**
   * @description Initializes form.
   */
  public initChargeTypeForm(): void {
    this.paymentTypeForm = this.builder.group({ paymentType: new UntypedFormControl(this.selected, [Validators.required]) });
  }

  /**
   * @description Detects if there is changes comparing db data with mat-select option data, then enables/disables the 'Save' button.
   */
  public onChangeDetected() {
    this.isSaveDisabled = this.paymentTypeForm.get(PAYMENT_TYPE).value === this.paymentTypeForm.invalid;
  }

  /**
   * @description Action for click on 'Cancel' button.
   */
  public onClickCancel(): void {
    this.dialogRef.close(DialogStandardFocus.CANCEL);
  }

  /**
   * @description Action for click on 'Close' button.
   */
  public onClickClose(): void {
    this.dialogRef.close(DialogStandardFocus.CLOSED);
  }

  /**
   * @description Action for click on 'Save' button.
   */
  public onClickSave(): void {
    const res = {
      dialogResult: DialogStandardFocus.CONFIRM,
      selectResult: this.paymentTypeForm.get(PAYMENT_TYPE).value
    };
    this.dialogRef.close(res);
  }
}
