/**
 * @description Codes for available voucher types for remission guides module.
 */
export enum VoucherTypesCodes {
  invoice = '01',
  salesAndProvisionOfServices = '03',
  creditNote = '04',
  debitNote = '05',
  remissionGuide = '06',
  retentionVoucher = '07',
}

/**
 * @description Description for available voucher types for remission guides module.
 */
export enum VoucherTypesDescription {
  invoice = 'FACTURA',
  salesAndProvisionOfServices = 'LIQUIDACIÓN DE COMPRA DE BIENES Y PRESTACIÓN DE SERVICIOS',
  creditNote = 'NOTA DE CRÉDITO',
  debitNote = 'NOTA DE DÉBITO',
  remissionGuide = 'GUÍA DE REMISIÓN',
  retentionVoucher = 'COMPROBANTE DE RETENCIÓN',
}
