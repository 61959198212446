import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { AUTHENTICATION_CONSTANTS } from './constants/authentication.constants';
import { LocalStorageService } from './services/utils/local-storage.service';
import { Profiles } from './enums';
import { RoleInUser } from './interfaces/role';
import { Session, User, WarehouseDefault,  } from './interfaces';
import { UserService } from './providers/user/user.service';

import * as _ from 'lodash';

const TRACING = 'Seguimiento';
const CLIENT = 'Cliente';

@Injectable()
export class AppService {
  public carrierOid: string;
  public cookieValue: Session;
  public infoSession: Session;
  public isRateInvalidAmount: Subject<boolean>;
  public profile: string;
  public shipperOid: string;
  public shipperSelected: Subject<string>;
  public showLoader: Subject<boolean>;
  public token: string;
  public username: string;
  public userOid: string;

  constructor(
    private cookieService: CookieService,
    private userService: UserService,
    private localStorageService: LocalStorageService
  ) {
    this.isRateInvalidAmount = new Subject<boolean>();
    this.shipperSelected = new Subject<string>();
    this.showLoader = new Subject<boolean>();
  }

  /**
   * @description Get cookie session from logged user
   * @return {Session}
   */
  private getCookieSession(): Session {
    const petition = this.cookieService.get('session');
    if (petition) {
      this.cookieValue = JSON.parse(petition);
      return this.cookieValue;
    } else {
      return this.cookieValue;
    }
  }

  /**
   * @description This is used to get the shipper's id
   * @returns the shipper's id
   */
  public getShipperOid(): any {
    const cookie = this.getCookieSession();
    if (cookie) {
      this.shipperOid = cookie.shipperOid || null;
      return this.shipperOid;
    } else {
      return this.shipperOid;
    }
  }

  /**
   * @description This is used to get the username
   * @returns the username from the cookies
   */
  public getShipperNameCookie(): any {
    const cookie = this.getCookieSession();
    if (cookie) {
      this.username = cookie.username;
      return this.username;
    } else {
      return this.username;
    }
  }

  /**
   * @description This is used to get the token
   * @returns the token from the cookies
   */
  public getToken(): any {
    const petition = this.cookieService.get('session');
    if (typeof petition === 'undefined' || !petition) {
      return this.token;
    } else {
      this.cookieValue = JSON.parse(petition);
      const token = this.cookieValue.token;
      return token;
    }
  }

  /**
   * @description This is used to get the profile
   * @returns the profile from the cookies
   */
  public getProfile(): any {
    const cookie = this.getCookieSession();
    if (cookie) {
      this.profile = cookie.profile;
      return this.profile;
    } else {
      return this.profile;
    }
  }

  /**
   * @description This is set the Shipper's id
   */
  public setShipper(shipperOid: string): void {
    this.shipperOid = shipperOid;
  }

  /**
   * @description Validate if exist shipperOid in the session.
   * @return Id of Shipper
   */
  public async getShipper(): Promise<string> {
    let shipperId;
    if (!this.shipperOid && (this.infoSession.perfil === TRACING || this.infoSession.perfil === CLIENT)) {
      const result: User = await this.userService.getUserById(this.infoSession.userOid);
      if (!result.embarcador._id) {
        shipperId = result.embarcador;
      } else {
        shipperId = result.embarcador._id;
      }
    } else if (this.infoSession) {
      shipperId = this.infoSession.embarcadoroid;
    }
    return shipperId;
  }

  /**
   * @description returns Carrier of the session cookie
   * @return {string}
   */
  public getCarrier(): string {
    const cookie = this.getCookieSession();
    if (cookie) {
      this.carrierOid = cookie.carrierOid;
      return this.carrierOid;
    } else {
      return this.carrierOid;
    }
  }

  /**
   * @description This is set the information from the session
   */
  public async setInfo(info) {
    this.infoSession = JSON.parse(info);
    this.userOid = this.infoSession.userOid;
    this.token = this.infoSession.token;
    this.profile = this.infoSession.profile;
    this.shipperOid = this.infoSession.embarcadoroid;
    this.carrierOid = this.infoSession.carrierOid;
    this.username = this.infoSession.username;
  }

  /**
   * @description This is used to get the  Oid from the cookies
   * @returns the userOid from the cookies
   */
  public getUserOid(): string {
    const petition = this.cookieService.get('session');
    if (petition.length > 0) {
      this.cookieValue = JSON.parse(petition);
      const userOid = this.cookieValue.userOid;
      return userOid;
    } else {
      return this.userOid;
    }
  }

  /**
   *@description This function actives the observable for the shipmentsBar
   *@return Observable of ShipmentRequest
   **/
  public getShipperSelected(): Observable<string> {
    return this.shipperSelected.asObservable();
  }

  /**
   *@description Helps to select one of the shipments
   *@params shipment: ShipmentRequest
   **/
  public selectShippper(shipper: string): void {
    this.shipperSelected.next(shipper);
  }

  /**
   *@description This function is to clear the observable when no shipment is selected
   **/
  public clearShipperSelected(): void {
    this.shipperSelected.next();
  }

  /**
   * @description actives the observable to the loader
   * @return observable of showLoader
   **/
  public getLoaderStatus(): Observable<boolean> {
    return this.showLoader.asObservable();
  }

  /**
   * @description receives the status of loader
   * @params {boolean} loader status to show/hide the loader
   **/
  public loaderStatus(loaderStatus: boolean): void {
    this.showLoader.next(loaderStatus);
  }

  /**
   * @description Get the user's default warehouse
   */
  public async getUserWarehouse(): Promise<WarehouseDefault> {
    const userOid = this.getUserOid();
    const user = await this.userService.getUserById(userOid);
    if (!_.isEmpty(user.warehouses)) {
      return user.warehouseDefault;
    } else {
      return;
    }
  }

  /**
   * @description Get the user's role
   */
  public async getUserRole(): Promise<RoleInUser> {
    const userOid = this.getUserOid();
    const user = await this.userService.getUserById(userOid);
    if (!_.isEmpty(user.role)) {
      return user.role;
    } else {
      return;
    }
  }

  /**
   * @description actives the observable of amount's input
   * @return observable of amount's input
   **/
   public AmountInputStatus(): Observable<boolean> {
    return this.isRateInvalidAmount.asObservable();
  }

  /**
   * @description receives status of amount's input
   * @params {boolean} invalidAmountInputStatus amount input status to enable/disable a button
   **/
  public checkAmountInputStatus(invalidAmountInputStatus: boolean): void {
    this.isRateInvalidAmount.next(invalidAmountInputStatus);
  }

  /**
   * @description - Gets logged in user's info from local storage.
   *
   * @returns { User } - Current user logged info
   */
  public getUserInfoFromStorage(): User {
    return this.localStorageService.getItemAsObject(AUTHENTICATION_CONSTANTS.LOCAL_STORAGE_USER_INFO_KEY)?.item;
  }

  /**
   * @description Get current logged TenantOid.
   * @returns {string} Current TenantOid.
   */
  public getTenantOid(): string {
    let tenantId: string;
    const profile = this.getProfile();

    switch (profile) {
      case Profiles.Shipper:
        tenantId = this.getShipperOid();
        break;
      case Profiles.Carrier:
        tenantId = this.getShipperOid();
        break;
      default:
        break;
    }

    return tenantId;
  }

  /**
   * @description Checks in data from user logged and returns the username.
   * @returns {string} Username found.
   */
  public getCurrentUsername(): string {
    const userInfo = this.getUserInfoFromStorage();

    return userInfo?.username;
  }
}
