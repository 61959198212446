import { ShipmentRowExtended } from '../../../interfaces';

export enum DialogChargeTypeLabels {
  paymentByType = 'Pago por tiempo',
  paymentByTypeValue = 'paymentByTime',
  paymentType = 'Tipo de pago',
  traditionalPayment = 'Pago tradicional',
  traditionalPaymentValue = 'traditionalPayment'
}

export interface IDialogChargeTypeLabels {
  paymentByType: string;
  paymentByTypeValue: string;
  paymentType: string;
  traditionalPayment: string;
  traditionalPaymentValue: string;
}

export interface IGeneralData {
  cancelButton: string;
  imgRef: string;
  resume?: string;
  saveButton: string;
  shipment: ShipmentRowExtended;
  title: string;
}
