<mat-grid-list cols="8" rowHeight="1:.30">
  <mat-grid-tile colspan="7" rowspan="1">
    <span class="dialog-title">
      <p>{{data.title}}</p>
    </span>
  </mat-grid-tile>
  <mat-grid-tile colspan="1" rowspan="1">
    <mat-icon
      id="dct-close-icon"
      class="dialog-close-icon"
      (click)="onClickClose()">
      close
    </mat-icon>
  </mat-grid-tile>
</mat-grid-list>
<div class="dialog-resume">
  {{data.resume}}
</div>
<mat-grid-list cols="2" rowHeight="1:.20">
    <mat-grid-tile colspan="1" rowspan="1">
      <form class="dialog-selector" id="dct-payment-type-form" [formGroup]="paymentTypeForm">
        <mat-form-field>
          <mat-select
            id="dct-payment-type-selector"
            placeholder="{{ labels.paymentType }}"
            formControlName="paymentType"
            (selectionChange)="onChangeDetected()"
            required>
            <mat-option [value]="labels.paymentByTypeValue">
              {{ labels.paymentByType }}
            </mat-option>
            <mat-option [value]="labels.traditionalPaymentValue">
              {{ labels.traditionalPayment }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </mat-grid-tile>
  <mat-grid-tile class="button-container" colspan="1" rowspan="1">
    <button
      id="dct-cancel-button"
      mat-button
      class="secondary-button"
      (click)="onClickCancel()">
      {{ data.cancelButton }}
    </button>
    <button
      id="dct-save-button"
      mat-raised-button
      class="primary-button align-right"
      (click)="onClickSave()"
      [disabled]="this.isSaveDisabled">
      {{ data.saveButton }}
    </button>
  </mat-grid-tile>
</mat-grid-list>
