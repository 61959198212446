import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import {
  Carrier,
  CarrierCreationResponse,
  CarrierNames,
  CarrierResponse,
  CarrierShipper,
  CreateCarrierBody } from 'src/app/interfaces';

const apiUrl = environment.apiUrl;
const carrrierRoute = '/lineaTransporte';
@Injectable()
export class CarrierProvider {

  constructor(private http: HttpClient, private _appService: AppService) { }

  /**
   * @description Gets carriers by shipper selected.
   * @param {string} shipperSelected - ShipperOId if someone was previously selected.
   * @param {boolean} isForCatalog - Flag to get all necessary data for catalog.
   * @param {boolean} hasInterfaceConfig - Flag to get carriers with courier config enabled.
   * @returns {Promise<CarrierResponse>} Carriers found.
   */
  public async getShipperCarriers(shipperSelected?: string, isForCatalog?: boolean,
    hasInterfaceConfig?: boolean): Promise<CarrierResponse> {
    let shipperOid;
    (shipperSelected) ? shipperOid = shipperSelected : shipperOid = this._appService.getShipperOid();
    let url = `${apiUrl}/embarcador/${shipperOid}${carrrierRoute}`;

    if (isForCatalog) {
      url += `?isForCatalog=${isForCatalog}`;
    }

    if (hasInterfaceConfig) {
      url += `?hasInterfaceConfig=${hasInterfaceConfig}`;
    }

    return await this.http.get<CarrierResponse>(url).toPromise();
  }

  /**
   * @description Get all carriers
   * @return {Array<object>} carriers
   */
  public async getAllCarriers(): Promise<Array<object>> {
    return await this.http.get<Array<object>>(`${apiUrl}${carrrierRoute}?activa=true`).toPromise();
  }

  /**
   * @description Get all carrier names
   * @return {Array<object>} carrier names
   */
  public async getAllCarrierNames(): Promise<Array<CarrierNames>> {
    return await this.http.get<Array<CarrierNames>>(`${apiUrl}${carrrierRoute}?nombres=1`).toPromise();
  }

  /**
   * @description Get all carrier´s rfc
   * @return {Array<string>} all carrier's rfc
   */
  public async getCarrierRFC(): Promise<Array<string>> {
    return await this.http.get<Array<string>>(apiUrl + '/lineaTransporte?getRFC=1')
    .toPromise();
  }

  /**
   * @description Get all carrier´s identifier
   * @return {Array<string>} all carrier's identifier
   */
  public async getCarrierIdentifier(): Promise<Array<string>> {
    return await this.http.get<Array<string>>(apiUrl + '/lineaTransporte?getIdentifier=1')
    .toPromise();
  }

  /**
   * @description Get carriers names
   * @return {CarrierShipper} all carriers shipper names
   */
  public async getCarrierNamesByShipper(shipperOid: string): Promise<CarrierShipper> {
    return await this.http.get<CarrierShipper>(apiUrl + '/embarcador/' + shipperOid + '/lineaTransporte?names=1')
    .toPromise();
  }

  /**
   * @description Create carrier
   */
  public async createCarrier(carrier: CreateCarrierBody, shipperOid: string): Promise<CarrierCreationResponse> {
    return await this.http.post<CarrierCreationResponse>(apiUrl + '/embarcador/' + shipperOid + '/lineaTransporte', carrier)
    .toPromise();
  }

  /**
   * @description Update carrier
   */
  public async updateCarrier(carrierOid: string, carrier: CreateCarrierBody): Promise<CarrierCreationResponse> {
    return await this.http.put<CarrierCreationResponse>(apiUrl + '/lineaTransporte/' + carrierOid, carrier)
    .toPromise();
  }

  /**
   * @description Delete carrier
   */
  public async removeCarrier(carrierOid: string): Promise<CarrierCreationResponse> {
    return await this.http.delete<CarrierCreationResponse>(apiUrl + '/lineaTransporte/' + carrierOid)
    .toPromise();
  }

  /**
   * @description Get Shipper Object Ids of carrier
   * @param carrierOid Carrier Object Id
   * @returns {Promise<Array<string>>} Shipper objects Ids
   */
  public async getShipperOidsOfCarrier(carrierOid: string): Promise<Array<string>> {
    return await this.http.get<Array<string>>(`${apiUrl}/carrier/${carrierOid}/shipper`).toPromise();
  }

  /**
   * @description Get carrier by Object Id
   * @param {string} carrierOid Carrier Object Id
   * @returns {Promise<Carrier>} Carrier Object
   */
  public async getCarrierByOid(carrierOid: string): Promise<Carrier> {
    return await this.http.get<Carrier>(`${apiUrl}/lineaTransporte/${carrierOid}`).toPromise();
  }

  /**
   * @description Gets carrier names by coincidence name
   * @param {string} carrierName Carrier name to search
   * @param {string} shipperOid Current shipper Object ID
   * @returns {Array<CarrierNames>} Carriers name found
   */
  public async getCarriersByCoincidenceName(carrierName: string, shipperOid?: string): Promise<Array<CarrierNames>> {
    return this.http.get<Array<CarrierNames>>(`${apiUrl}/shippers/${shipperOid}/carriers/${carrierName}`).toPromise();
  }
}
