import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { Subscription } from 'rxjs';

import {
  AccountBody,
  ArrayOids,
  OrderForShipment,
  OrdersApi,
  ProductsDetail,
  RemissionGuide,
  ShipperConfiguration
} from '../../interfaces';
import {
  AccountData,
  BuildOrdersDataParams,
  BuildRemissionFolioParam,
  CarrierData,
  DestinationData,
  GetRemissionFoliosParams,
  Order,
  OrderData,
  OrderDataGetAuthorization,
  PageContent,
  ProductData,
  ProductsOrganized,
  RemissionGuideData,
  RemissionGuidesContent,
  RemissionGuidesFormatParams,
  RemissionGuidesPrintableData,
  SetProductsContentInPageParams,
  Shipment
} from '../../interfaces/remission-guides';
import { AccountProvider } from '../../providers/accounts/account-provider.service';
import { AppConstants } from '../../constants/app-constants.constants';
import { AppService } from '../../app.service';
import { ConfigurationProvider } from '../../providers/configuration/configuration.provider.service';
import { DateFormatService } from '../date-format.service';
import { LanguageChangeEventService } from '../translate/language-change-event.service';
import { LanguageConstants } from '../../constants/language.constants';
import { LanguageTranslateService } from '../translate/language-translate.service';
import { LoadPlanService } from '../load-plan/load-plan.service';
import { OrderProvider } from '../../providers/orders/order-provider.service';
import { REMISSION_GUIDES_PROPERTIES } from '../../pages/shipments/remission-guides/remission-guides-constants';
import { RemissionGuidesAuthorizationStatus } from '../../enums/remissionGuidesStatus';
import { RemissionGuidesLabels, RemissionGuidesSriStatusLabels } from '../../interfaces/language.interface';
import { RemissionGuidesProviderService } from '../../providers/remission-guides/remission-guides.provider.service';
import { ToastrAlertsService } from '../utils/toastr-alerts.service';
import { UtilsService } from '../utils.service';
import { VoucherTypesCodes } from '../../enums/voucherTypes';

@Injectable()

/**
 * Service with common functionalites used in remission guides module.
 */
export class RemissionGuidesService {
  public currentDate: Date;
  public isCurrentEnviromentProductive: boolean;
  public labels: RemissionGuidesLabels;
  public languageSubscription: Subscription;
  public shipperConfig: ShipperConfiguration;
  public sriStatusLabels: RemissionGuidesSriStatusLabels;
  public tenantId: string;

  /**
   * @description Contains instances of services used for correctly work of this component.
   * @param {AccountProvider} accountProvider - Service to get all information of account.
   * @param {AppService} appService - Main service of project with global functions.
   * @param {ConfigurationProvider} configProvider - Service with calls to endpoint related with shipper config.
   * @param {DateFormatService} dateFormatService - Service with methods to generate timestamps in custom output.
   * @param {LanguageChangeEventService} languageChangeEventService - Service to detection of changes in language active for user.
   * @param {LanguageTranslateService} languageTranslateService - Service to retrieve differents all component labels in active language.
   * @param {LoadPlanService} loadPlanService - Service with main methods related with display info from contained load plan.
   * @param {OrderProvider} orderProvider - Service with calls to endpoint related with orders.
   * @param {RemissionGuidesProviderService} remissionGuidesProvider - Service provider for remission guides module.
   * @param {ToastrAlertsService} toast - Service to can display differents toaster to user.
   * @param {UtilsService} utilsService - Service with utils method for common operations.
   */
  constructor(
    private accountProvider: AccountProvider,
    private appService: AppService,
    private configProvider: ConfigurationProvider,
    private dateFormatService: DateFormatService,
    private languageChangeEventService: LanguageChangeEventService,
    private languageTranslateService: LanguageTranslateService,
    private loadPlanService: LoadPlanService,
    private orderProvider: OrderProvider,
    private remissionGuidesProvider: RemissionGuidesProviderService,
    private toast: ToastrAlertsService,
    private utilsService: UtilsService
  ) {
    this.setLanguage();
    this.subscribeLanguageChangeEvents();
    this.getRemissionGuidesComponentLabels();
    this.tenantId = this.appService.getShipperOid();
    this.currentDate = new Date();
    this.isCurrentEnviromentProductive = this.utilsService.isAProductiveEnviroment();
  }

  /**
   * @description Filters with value coincidences for the orders or shipments results.
   * @param {string} value - Value typed by user on the input field.
   * @param {MatTableDataSource<Shipment> | MatTableDataSource<Order>} dataSource - Current data source
   * from grid displayed in orders or shipments table results.
   */
  public applyFilterInResults(value: string, dataSource: MatTableDataSource<Shipment> | MatTableDataSource<Order>): void {
    dataSource.filterPredicate = (data: object, filter: string) => {
      const accumulator = (currentTerm: string, key: string) => {
        return this.nestedFilterCheck(currentTerm, data, key);
      };
      const dataString = Object.keys(data).reduce(accumulator, AppConstants.EMPTY_STRING).toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();

      return dataString.indexOf(transformedFilter) !== AppConstants.INVALID_INDEX;
    };
    dataSource.filter = value.trim().toLowerCase();
  }

  /**
   * @description Builds a single description with 'motoDetails' info from a provided product.
   * @param {ProductsDetail} product - Order product.
   * @returns {string} A unique string with all data from products like engine, number, chassis, etc...
   */
  public buildDetailDescriptionFromProduct(product: ProductsDetail): string {
    const series = product?.motoDetail?.series ? product?.motoDetail?.series : this.labels.noInfo;
    const chassis = product?.motoDetail?.chassis ? product?.motoDetail?.chassis : this.labels.noInfo;
    const engine = product?.motoDetail?.engineNumber ? product?.motoDetail?.engineNumber : this.labels.noInfo;
    const origin = product?.motoDetail?.originCountry ? product?.motoDetail?.originCountry : this.labels.noInfo;
    const color = product?.motoDetail?.color ? product?.motoDetail?.color : this.labels.noInfo;
    const productionYear = product?.motoDetail?.productionYear ? product?.motoDetail?.productionYear : this.labels.noInfo;

    return `${this.labels?.productSeries}${series}, ` + `${this.labels?.productChassis}${chassis}, ` +
    `${this.labels?.productEngineNumber}${engine}, ` + `${this.labels?.productOriginCountry}${origin}, ` +
    `${this.labels?.productProductionYear}${productionYear}, ` + `${this.labels?.productColor}${color}.`;
  }

  /**
   * @description Builds a new order data taking and complementing data from shipment, carrier from shipment,shipment accounts, etc.
   * @param {BuildOrdersDataParams} params - Params with info from all acounts associated to shipment, order data, shipment from order, etc.
   * @returns {Order} A new order data.
   */
  public buildOrderData(params: BuildOrdersDataParams): Order {
    const remissionGuideData: RemissionGuideData = params.order.remissionGuides?.length ?
      this.setRemissionGuideDataInCurrentShipment(params.order.remissionGuides, params.shipmentData.shipmentId) : null;
    params.remissionGuideData = remissionGuideData;
    const accountData: AccountBody = this.setAccountInfo(params.order.account._id, params.allAccountsInfo);
    params.accountData = accountData;

    return {
      _id: params.order._id,
      accessCode: this.buildOrderAccessCode(params),
      accountData: accountData,
      appointmentHour: params.order.appointmentHour,
      authorizationMessage: null,
      authorizationNumber: null,
      carrier: params.shipmentData.fullCarrierData ?? null,
      classType: null,
      creationDate: params.order.creationDate ?? null,
      currentShipment: params.shipmentData.fullShipmentData ?? null,
      customDocument: params.orderDataInShipment.customDocument,
      dateSentToAuthorization: null,
      deliveryClass: null,
      deliveryDate: params.order.deliveryDate ?? null,
      deliveryDoc: params.orderDataInShipment.deliveryDoc ?? null,
      deliveryNumber: params.orderDataInShipment.deliveryNum ?? null,
      deliveryTime: params.order.deliveryDate ?? null,
      destination: params.order.destination,
      docNumber: null,
      driver: params.shipmentData.fullDriverData,
      driverFullName: params.shipmentData?.driver,
      emissionPoint: params.orderDataInShipment.emissionPoint ?? null,
      establisment: params.orderDataInShipment.establishment ?? null,
      grouperNumber: params.order.orderGrouper ?? null,
      invoiceDoc: params.order.orderDoc ?? null,
      invoiceNumber: params.order.orderNum ?? null,
      isOrderSelected: false,
      isShipmentConfirmedByInterface: params.shipmentData.hasBeenConfirmedByInterface,
      orderId: params.order.identifier,
      origin: params.order.origin,
      originWarehouseData: params.shipmentData.originWarehouseData ?? null,
      plates: params.shipmentData.plates,
      products: params.orderDataInShipment.products?.length ? params.orderDataInShipment.products : null,
      productsData: this.buildProductsData(params.orderDataInShipment, params.order),
      proofOfPurchaseDate: params.orderDataInShipment.proofOfPurchaseDate ?? null,
      proofOfPurchaseNumber: params.orderDataInShipment.proofOfPurchaseNumber ?? null,
      remissionGuideData: remissionGuideData,
      route: this.buildOrderRoute(params.order),
      shipmentId: params.shipmentData.shipmentId,
      shipments: params.order.shipment,
      sriStatus: RemissionGuidesAuthorizationStatus.noGenerated,
      totalPieces: params.orderDataInShipment.pieces,
      totalVolume: params.orderDataInShipment.volume,
      tradingAutorizationNumber: params.orderDataInShipment.tradingAutorizationNumber ?? null,
      transferReason: null,
      vehicleType: params.shipmentData.vehicleType
    };
  }

  /**
   * @description Builds order route with info from origin and destination municipality.
   * @param {OrdersApi} order - Order to build acnd check origin/destination data.
   * @returns {string} A string with route info (originMunicipality - destinationMunicipality).
   */
  public buildOrderRoute(order: OrdersApi): string {
    return (order?.origin?.municipality && order?.destination?.municipality) ?
      `${order?.origin?.municipality} - ${order?.destination?.municipality}` : null;
  }

  /**
   * @description Receives an array of orders to be processed and build necessary info for integrator.
   * @param {Array<Order>} orders - Orders selected to be processed.
   * @returns {Array<OrderDataGetAuthorization>} - An array with new orders data needed to be authorized.
   */
  public buildOrdersDataToGetAuthorization(orders: Array<Order>): Array<OrderDataGetAuthorization> {
    const ordersToRequestAuth: Array<OrderDataGetAuthorization> = orders.map((order: Order) => {
      return {
        accessKey: order.accessCode ? order.accessCode : AppConstants.EMPTY_STRING,
        account: this.buildOrderAccountData(order),
        carrier: this.buildOrderCarrierData(order),
        destination: this.buildOrderDestinationData(order),
        emissionPoint: order.emissionPoint ?? AppConstants.EMPTY_STRING,
        establishment: order.establisment ?? AppConstants.EMPTY_STRING,
        guideConsecutive: order.remissionGuideData?.consecutive ?? AppConstants.EMPTY_STRING,
        order: this.buildOrderDataForAuthorization(order),
        products: order.products?.length ? this.buildOrderProductsForAuthorization(order.products) : null,
        proofOfPurchaseDate: AppConstants.EMPTY_STRING,
        proofOfPurchaseNumber: AppConstants.EMPTY_STRING,
        route: order.route,
        tradingAutorizationNumber: AppConstants.EMPTY_STRING,
        vehicle: { plates: order.currentShipment?.transport?.plates },
        warehouse: { address: order.originWarehouseData.addressInfo.address }
      };
    });

    return ordersToRequestAuth;
  }

  /**
   * @description Receives an array of orders and builds products data to display in printable format for each order provided.
   * @param {Array<Order>} orders - All orders to process products data (if applies).
   * @returns {Array<RemissionGuidesContent>} All orders provided with neccesary info to display in the printable format.
   */
  public buildPrintableFormatContent(orders: Array<Order>): Array<RemissionGuidesContent> {
    const availableOrders = structuredClone(orders);
    const allOrdersData: Array<RemissionGuidesContent> = [];

    for (const order of availableOrders) {
      const pageNumber = 1;
      const currentOrder = order;
      const orderContent: RemissionGuidesContent = {
        detailedContentByPages: [],
        order: currentOrder,
        summarizedContentByPages: [],
        totalProductsVolume: this.setTotalVolumenForPrintableFormat(order.productsData)
      };
      const params: RemissionGuidesFormatParams = {
        allOrdersContent: allOrdersData,
        currentOrder: structuredClone(currentOrder),
        orderContent: orderContent,
        pageNumber: pageNumber
      };

      if (currentOrder.productsData?.sumarizedProducts?.length) {
        this.setCurrentOrderDataAndBuildProductsOrder(currentOrder, params, false);

        if (currentOrder.productsData?.detailedProducts?.length) {
          this.setCurrentOrderDataAndBuildProductsOrder(currentOrder, params, true);
        }
      } else {
        allOrdersData.push(orderContent);
      }
    }

    return allOrdersData;
  }

  /**
   * @description Builds printable title with prefix label in current language and timestamp from current date is being printed.
   * (prefix-DDMMYYYY-HHMMSS).
   * @returns {string} Title from doc as string.
   */
  public buildPrintableFormatTitle(): string {
    const currentDate = new Date();
    const formatedDate = this.dateFormatService.buildJustDateTimestamp(currentDate);
    const formatedTime = this.dateFormatService.buildJustTimeTimestamp(currentDate);

    return this.labels?.remissionGuidesPrintableTitle + formatedDate + AppConstants.DASH + formatedTime;
  }

  /**
   * @description Builds new products data from a provided order to display data in printable format.
   * @param {OrderForShipment} orderDataInShipment - Data from the order saved in shipment.
   * @param {OrdersApi} orderData - Current order data (from orderDB).
   * @returns {ProductsOrganized} A new property with data from order products organized by summarized and detailed sections.
   */
  public buildProductsData(orderDataInShipment: OrderForShipment, orderData: OrdersApi): ProductsOrganized {
    if (!orderData.products?.length || !orderDataInShipment.products?.length) {
      return null;
    }
    const allProductsInfo: ProductsOrganized = {
      detailedProducts: [],
      sumarizedProducts: [],
      totalProductsVolume: 0
    };

    for (const product of orderDataInShipment.products) {
      allProductsInfo.sumarizedProducts.push(product);

      if (!product.motorcycleDetails?.length) {
        allProductsInfo.totalProductsVolume = allProductsInfo.totalProductsVolume + product.volume;
      }

      if (product.motorcycleDetails?.length) {
        this.buildDetailedProductsData(product, allProductsInfo);
      }
    }

    return allProductsInfo;
  }

  /**
   * @description Check if shipper have necessary config to send remission guides to be authorized.
   * @returns {boolean} True if shipper has enabled config to send remission guides to authorization,
   * and configurated url and token where data will be sent to be atuhorized.
   */
  public async canRemissionGuidesBeAuthorized(): Promise<boolean> {
    const config = await this.configProvider.getShipperConfig(this.tenantId);

    return Boolean(config.remissionGuides?.isRemissionGuideAuthorizationEnabled &&
      config.remissionGuides?.requestAuthorizationToken && config.remissionGuides.requestAuthorizationUrl);
  }

  /**
   * @description Filters accout names for orders provided to found full data from accounts.
   * @param {Array<OrdersApi>} orders - Orders to found account data.
   * @returns {Promise<Array<AccountBody>>} - Full data from accounts associated to orders provided.
   */
  public async getAccountFullDataFromOrders(orders: Array<OrdersApi>): Promise<Array<AccountBody>> {
    const accountsOIds: Array<string> = [...new Set(orders.map((order: OrdersApi) => {
      return order.account._id;
    }))];
    const response = await this.accountProvider.getShipperAccountsByOIds(accountsOIds, this.tenantId);

    return response?.item;
  }

  /**
   * @description Builds data to display in printable format for orders selected.
   * @param {Array<Order>} orders - Orders wants to display remission guide content.
   * @param {string} logo - Logo url to display in printable format header.
   * @returns {RemissionGuidesPrintableData} Content to display in component that prints remission guides.
   */
  public getDataForPrintableFormat(orders: Array<Order>, logo: string): RemissionGuidesPrintableData {
    const data: RemissionGuidesPrintableData = {
      contentByOrders: this.buildPrintableFormatContent(orders),
      logo: logo,
      title: this.buildPrintableFormatTitle()
    };

    return data;
  }

  /**
   * @description Builds an object with params to execute request in endpoint tghat builds new remisision guides for orders provided.
   * @param {Array<Order>} orders - Orders data wants to get the remission guide folio.
   * @param {string} tenantId - Object ID from tenant.
   * @param {string} user - Username is executing request.
   * @returns {GetRemissionFoliosParams} A single data with all necessary params for request.
   */
  public getRemissionGuideFolioParams(orders: Array<Order>, tenantId: string, user: string): GetRemissionFoliosParams {
    const ordersWithoutRemissionGuide: Array<Order> = orders.filter((order: Order) => {
      return !order.remissionGuideData;
    });
    const remissionGuidesParams: GetRemissionFoliosParams = {
      body: [],
      tenantOId: tenantId,
      user: user
    };

    for (const order of ordersWithoutRemissionGuide) {
      const param: BuildRemissionFolioParam = {
        emitterPoint: order.emissionPoint ?? REMISSION_GUIDES_PROPERTIES.orderEmitterPointExample,
        establishment: order.establisment ?? REMISSION_GUIDES_PROPERTIES.orderEstablismentExample,
        orderOId: order._id,
        shipmentId: order.shipmentId
      };
      remissionGuidesParams.body.push(param);
    }

    return remissionGuidesParams;
  }

  /**
   * @description Retrieves labels used in view.
   * @returns {Promise<RemissionGuidesLabels>} Labels from remission guides view.
   */
  public async getRemissionGuidesComponentLabels(): Promise<RemissionGuidesLabels> {
    try {
      this.labels = await this.languageTranslateService.getLanguageLabels(LanguageConstants.REMISSION_GUIDES_LABELS);
      this.sriStatusLabels = await this.languageTranslateService.getLanguageLabels(LanguageConstants.REMISSION_GUIDES_SRI_STATUS_LABELS);

      return this.labels;
    } catch (error) {
      this.toast.errorAlert(this.labels.genericErrorMsg);
    }
  }

  /**
   * @description Executes request to get remission guides folios and then set it in orders where folio belongs.
   * @param {GetRemissionFoliosParams} params - A single data with all necessary params to execute request.
   * @param {Array<Order>} orders - Orders for remission guides were created.
   */
  public async getRemissionGuidesFolios(params: GetRemissionFoliosParams, orders: Array<Order>): Promise<void> {
    const response = await this.remissionGuidesProvider.getRemissionGuidesFolios(params);

    if (response?.item?.length) {
      for (const remisionGuide of response.item) {
        const orderInfo: Order = orders.find((order: Order) => {
          return order.shipmentId === remisionGuide.shipment && order._id === remisionGuide.order;
        });
        orderInfo.remissionGuideData = this.buildRemissionGuideData(remisionGuide);
      }
    }
  }

  /**
   * @description Receives an SRI status to display label in current language.
   * @param {string} status - Status from order results to check and found label.
   * @returns {string} Label found for status provided.
   */
  public getSriStatusLabel(status: string): string {
    switch (status) {
      case RemissionGuidesAuthorizationStatus.noGenerated:
        return this.sriStatusLabels?.noGenerated;
      case RemissionGuidesAuthorizationStatus.inValidation:
        return this.sriStatusLabels?.inValidation;
      case RemissionGuidesAuthorizationStatus.rejected:
        return this.sriStatusLabels?.rejected;
      case RemissionGuidesAuthorizationStatus.returned:
        return this.sriStatusLabels?.returned;
      case RemissionGuidesAuthorizationStatus.authorizated:
        return this.sriStatusLabels?.authorizated;
      default:
        return this.labels?.noInfo;
    }
  }

  /**
   * @description Checks if all orders provided have remission guides assigned.
   * @param {Array<Order>} orders - Orders to check if already have a remission guide assigned.
   * @returns {boolean} True if all orders have remission guides assigned. Otherwise false.
   */
  public isEveryOrderWithRemissionGuides(orders: Array<Order>): boolean {
    return orders.every((order: Order) => {
      return order.remissionGuideData;
    });
  }

  /**
   * @description Check values for nested objects in datasource results.
   * @param {string} search - Nested search.
   * @param {any} data - Field on table.
   * @param {string} key - Field position.
   * @returns {string} Properties with coincidences with provided values.
   */
  public nestedFilterCheck(search: string, data: any, key: string): string {
    if (typeof data[key] === AppConstants.OBJECT) {
      for (const position in data[key]) {
        if (data[key][position] !== null) {
          search = this.nestedFilterCheck(search, data[key], position);
        }
      }
    } else {
      search += data[key];
    }

    return search;
  }

  /**
   * @description Process orders results to map neccesary info for shipments results.
   * @param {Array<OrdersApi>} orders - Orders obtained to process.
   * @param {Array<Shipment>} shipments - Shipments where belongs orders provided.
   * @returns {Promise<Array<Order>>} Orders received but with only necessary info.
   */
  public async processOrdersResults(orders: Array<OrdersApi>, shipments: Array<Shipment>): Promise<Array<Order>> {
    const allAccountsInfo = await this.getAccountFullDataFromOrders(orders);
    const results: Array<Order> = orders.map((order: OrdersApi) => {
      const shipmentData = shipments.find((shipment: Shipment) => {
        return shipment.orders.find((shipmentOrder: Order) => {
          return shipmentOrder._id === order._id;
        });
      });
      const orderDataInShipment = this.setOrderInfoFromShipmentOrders(order._id, shipmentData.ordersDataInShipment);
      const params: BuildOrdersDataParams = {
        allAccountsInfo: allAccountsInfo,
        order: order,
        orderDataInShipment: orderDataInShipment,
        shipmentData: shipmentData
      };

      return this.buildOrderData(params);
    });

    return results;
  }

  /**
   * @description Search orders info and complements new info with shipments where belongs.
   * @param {Array<string>} ordersOIds - Array with ordersOId's wants to search info.
   * @param {Array<string>} shipmentsIds - Array with shipments ID's where orders belongs.
   * @param {Array<Shipment>} shipments - Array with shipments data selected.
   * @returns {Promise<Array<Order>>} An array with only useful data to use in view.
   */
  public async searchOrdersInfo(ordersOIds: Array<string>, shipmentsIds: Array<string>, shipments: Array<Shipment>): Promise<Array<Order>> {
    const searchParms: ArrayOids = { ordersIds: ordersOIds };
    const response = await this.orderProvider.getOrderByOids(searchParms);
    const ordersInfo = await this.processOrdersResults(response as unknown as Array<OrdersApi>, shipments);

    return ordersInfo;
  }

  /**
   * @description Searchs info from orders associated in shipments provided.
   * @param {Array<Shipment>} shipments - An array with shipments to extract current orders info.
   * @returns {Promise<Array<Order>>} An array with only useful data to use in view.
   */
  public async searchShipmentsOrders(shipments: Array<Shipment>): Promise<Array<Order>> {
    const allShipmentOrdersIds = [];
    const allShipmentIds = [];

    for (const shipment of shipments) {
      const ordersIds: Array<string> = shipment.orders.map((order: Order) => {
        return order._id;
      });
      allShipmentOrdersIds.push(...ordersIds);
      allShipmentIds.push(shipment.shipmentId);
    }

    return await this.searchOrdersInfo(allShipmentOrdersIds, allShipmentIds, shipments);
  }

  /**
   * @description Sets account info for account OId provided into a n array of provided accounts.
   * @param {string} accountOId - OId from account to match.
   * @param {Array<AccountBody>} accounts - Accounts full data where an account data will be searched.
   * @returns {AccountBody} Full data from account found.
   */
  public setAccountInfo(accountOId: string, accounts: Array<AccountBody>): AccountBody {
    return accounts.find((account: AccountBody) => {
      return account._id.toString() === accountOId.toString();
    });
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @param {string} languageKey - Optional language key string, default is spanish 'es'.
   */
  public setLanguage(languageKey?: string): void {
    this.languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Sets order info but with order data saved in shipment.
   * @param {string} orderOId - Order OId to find data in shipment.
   * @param {Array<OrderForShipment>} shipmentOrders - All orders associted in shipment where order data will be found.
   * @returns {OrderForShipment} Order data found in shipment.
   */
  public setOrderInfoFromShipmentOrders(orderOId: string, shipmentOrders: Array<OrderForShipment>): OrderForShipment {
    return shipmentOrders.find((order: OrderForShipment) => {
      return order._id.toString() === orderOId.toString();
    });
  }

  /**
   * @description Builds a label to display in print multiple remission guides button.
   * @param {number} recordsSelected - Number of records selected to concat in label.
   * @returns {string} Label built with default label of button and records quantities selected.
   */
  public setPrintGuidesTitleForMultipleRecords(recordsSelected: number): string {
    if (recordsSelected) {
      return `${this.labels?.guidesPrint} (${recordsSelected})`;
    }

    return this.labels?.guidesPrint;
  }

  /**
   * @description Splits a remission guides provided to return only consecutive for that remission guide folio.
   * @param {string} remissionGuide - Folio provided to split.
   * @returns {string} Consecutive for remission guide provided.
   */
  public setRemissionGuideConsecutive(remissionGuide: string): string {
    const consecutivePosition = 2;
    const folio = remissionGuide.split(AppConstants.DASH);

    return folio[consecutivePosition];
  }

  /**
   * @description Receives an array of remission guides to set the remission guide folio (if exists) in current shipment.
   * @param {Array<RemissionGuide>} remissionGuides - Array of remission guides associated in order.
   * @param {string} currentShipment - Shipment ID wants to search remission guide.
   * @returns {RemissionGuideData} Remission guide data for order in current shipment.
   */
  public setRemissionGuideDataInCurrentShipment(remissionGuides: Array<RemissionGuide>, currentShipment: string): RemissionGuideData {
    const remissionGuideData = remissionGuides.find((remissionGuide: RemissionGuide) => {
      return remissionGuide.shipment === currentShipment;
    });

    if (remissionGuideData) {
      return this.buildRemissionGuideData(remissionGuideData);
    } else {
      return null;
    }
  }

  /**
   * @description Builds a label to display in print multiple remission guides button.
   * @param {number} recordsSelected - Number of records selected to concat in label.
   * @returns {string} Label built with default label of button and records quantities selected.
   */
  public setRequestAuthTitleForMultipleRecords(recordsSelected: number): string {
    if (recordsSelected) {
      return `${this.labels?.authorizationRequest} (${recordsSelected})`;
    }

    return this.labels?.authorizationRequest;
  }

  /**
   * @description Subscribes to the language service to detect changes in the languaje selected and refresh component's labels.
   */
  public subscribeLanguageChangeEvents(): void {
    this.languageSubscription = this.languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getRemissionGuidesComponentLabels();
      },
      (error) => { }
    );
  }

  /**
   * @description Builds detailed "type" products.
   * @param {ProductsDetail} product - Product to process and buils detailed products asscociated in "motorcycleDetails" property.
   * @param {ProductsOrganized} productsInfo - Contains data will be used in printable format
   * with all data from summarized products and detailed products.
   */
  private buildDetailedProductsData(product: ProductsDetail, productsInfo: ProductsOrganized): void {
    const baseProductData = structuredClone(product);

    do {
      const auxProductData = structuredClone(product);
      auxProductData.motoDetail = baseProductData.motorcycleDetails[0];
      productsInfo.detailedProducts.push(auxProductData);
      productsInfo.totalProductsVolume = productsInfo.totalProductsVolume + product.volume;
      baseProductData.motorcycleDetails.splice(0, 1);
    } while (baseProductData.motorcycleDetails?.length);
  }

  /**
   * @description Builds order access code with info like emission date, voucher type, remission guide consecutive and etc.
   * @param {BuildOrdersDataParams} params - Params with info from order data, shipment from order, etc.
   * @returns {string} - Access code for provided order.
   */
  private buildOrderAccessCode(params: BuildOrdersDataParams): string {
    const emissionDate = this.dateFormatService.buildJustDateTimestamp(this.currentDate);
    const voucherType = VoucherTypesCodes.invoice.toString();
    const ruc = params.accountData.rfc;
    const enviroment = this.isCurrentEnviromentProductive ? REMISSION_GUIDES_PROPERTIES.productiveEnviromentValue : 1;
    const serie = REMISSION_GUIDES_PROPERTIES.serieExample;
    const sequencial = params.remissionGuideData ? params.remissionGuideData.consecutive :
      REMISSION_GUIDES_PROPERTIES.remissionGuideSequencialExample;
    const emissionType = 1;
    const numericCode = REMISSION_GUIDES_PROPERTIES.numericCodeExample;
    const checkDigit = REMISSION_GUIDES_PROPERTIES.checkDigitExample;

    return emissionDate + voucherType + ruc + enviroment + serie + sequencial + numericCode + emissionType + checkDigit;
  }

  /**
   * @description Builds order account data necessary to receive authorization.
   * @param {Order} order - Current Order data in remission guides flow.
   * @returns {AccountData} Necessary data for account property from order to be sent to authorization.
   */
  private buildOrderAccountData(order: Order): AccountData {
    return {
      accountingRequired: REMISSION_GUIDES_PROPERTIES.negation,
      address: order.accountData.accountAddress.address,
      fiscalIdentifier: order.accountData.rfc,
      name: order.accountData.nombre,
      specialContributor: AppConstants.EMPTY_STRING
    };
  }

  /**
   * @description Builds order carrier data necessary to receive authorization.
   * @param {Order} order - Current Order data in remission guides flow.
   * @returns {CarrierData} Necessary data for carrier property from order to be sent to authorization.
   */
  private buildOrderCarrierData(order: Order): CarrierData {
    return {
      fiscalIdentifier: order?.carrier?.rfc ?? AppConstants.EMPTY_STRING,
      name: order.carrier.nombre
    };
  }

  /**
   * @description Builds order data necessary to receive authorization.
   * @param {Order} order - Current Order data in remission guides flow.
   * @returns {OrderData} Necessary data from order to be sent to authorization.
   */
  private buildOrderDataForAuthorization(order: Order): OrderData {
    return {
      _id: order._id,
      creationDate: order.creationDate?.toString() ?? AppConstants.EMPTY_STRING,
      deliveryDate: order.deliveryDate?.toString() ?? AppConstants.EMPTY_STRING,
      deliveryDocDescription: order.transferReason ?? AppConstants.EMPTY_STRING,
      identifier: order.orderId
    };
  }

  /**
   * @description Builds order destinatiob data necessary to receive authorization.
   * @param {Order} order - Current Order data in remission guides flow.
   * @returns {DestinationData} Necessary data for destination property from order to be sent to authorization.
   */
  private buildOrderDestinationData(order: Order): DestinationData {
    return {
      address: order?.destination?.address ?? AppConstants.EMPTY_STRING,
      id: order?.destination?.locationId ?? AppConstants.EMPTY_STRING,
      name: order?.destination?.name ?? AppConstants.EMPTY_STRING
    };
  }

  /**
   * @description Builds order products data necessary to receive authorization.
   * @param {Array<ProductsDetail>} products - Current order products data in remission guides flow.
   * @returns {Array<ProductData>} An array with all products provide but in structure expected by integrator.
   */
  private buildOrderProductsForAuthorization(products: Array<ProductsDetail>): Array<ProductData> {
    return products.map((product: ProductsDetail) => {
      return {
        barcode: product?.barcode ?? AppConstants.EMPTY_STRING,
        code: product?.code ?? AppConstants.EMPTY_STRING,
        description: product?.name ?? AppConstants.EMPTY_STRING,
        quantity: product?.total ?? AppConstants.ZERO
      };
    });
  }

  /**
   * @description Builds and process a remission guide folio associted in the order to organize info into.
   * @param {RemissionGuide} remissionGuide - Current remission guide associated in order.
   * @returns {RemissionGuideData} - A property with remission guide splitted and organize it.
   */
  private buildRemissionGuideData(remissionGuide: RemissionGuide): RemissionGuideData {
    const establishmentPosition = 0;
    const emitterPointPosition = 1;
    const consecutivePosition = 2;
    const folioSplitted = remissionGuide.folio.split(AppConstants.DASH);
    const remissionGuideData: RemissionGuideData = {
      consecutive: folioSplitted[consecutivePosition],
      createdBy: remissionGuide.user,
      emitterPoint: folioSplitted[emitterPointPosition],
      establishment: folioSplitted[establishmentPosition],
      fullRemissionGuideFolio: remissionGuide.folio,
      shipment: remissionGuide.shipment
    };

    return remissionGuideData;
  }

  /**
   * @description Calculates an approximated of available rows in last page of summarized content from order.
   * @param {RemissionGuidesFormatParams} remissionGuidesParams - Params used in full process of build printable format content.
   * @returns {number} Quantity of available rows in last page occupied by sumarrized content.
   */
  private setAvailableRowsForDetails(remissionGuidesParams: RemissionGuidesFormatParams): number {
    const summarizedContent = remissionGuidesParams.orderContent.summarizedContentByPages;
    const lastPageSummarizedContent = summarizedContent[summarizedContent.length - 1];

    if (!remissionGuidesParams?.orderContent?.detailedContentByPages?.length) {
      return REMISSION_GUIDES_PROPERTIES.maxRowsAvailableInPage - lastPageSummarizedContent.products.length -
      REMISSION_GUIDES_PROPERTIES.rowsQuantityRequiredForFirstProductInPage;
    }

    return REMISSION_GUIDES_PROPERTIES.maxRowsAvailableInPage - REMISSION_GUIDES_PROPERTIES.rowsQuantityRequiredForFirstProductInPage;
  }

  /**
   * @description Builds and clones order data provided to set content for summarized/detailed section from printable format.
   * @param {Order} currentOrder - Current order is being processed.
   * @param {RemissionGuidesFormatParams} params - Contains additional info that is being used in overall way,
   * to build remission guides content for orders.
   * @param {boolean} isDetailedContent - Determinates with this flag what products will be processed.
   * True will process detailed products and false process summarized products in order.
   */
  private setCurrentOrderDataAndBuildProductsOrder(currentOrder: Order, params: RemissionGuidesFormatParams, isDetailedContent: boolean) {
    params.currentOrder = structuredClone(currentOrder);
    params.currentOrder.products = isDetailedContent ? currentOrder.productsData.detailedProducts :
      currentOrder.productsData.sumarizedProducts;
    this.setProductsContentByPages(params, isDetailedContent);
  }

  /**
   * @description Sets max content with orders products can be displayed by pages.
   * @param {RemissionGuidesFormatParams} remissionGuidesParams - Params used in full process of build printable format content.
   * @param {boolean} isBuildingDetailedContent - Flag to know if content built is for detailed section. If is received as true will build
   * content for summarized content.
   */
  private setProductsContentByPages(remissionGuidesParams: RemissionGuidesFormatParams, isBuildingDetailedContent: boolean): void {
    const isFirstPageFromOrder = isBuildingDetailedContent ? false : true;

    do {
      const isMaxContentPerPageReached = false;
      const pageContent: PageContent = {
        page: remissionGuidesParams.pageNumber,
        products: []
      };
      let availableRowsInCurrentPage: number;

      if (isBuildingDetailedContent) {
        availableRowsInCurrentPage = this.setAvailableRowsForDetails(remissionGuidesParams);
      } else {
        availableRowsInCurrentPage = isFirstPageFromOrder ? REMISSION_GUIDES_PROPERTIES.maxRowsAvailableInFirstPage :
          REMISSION_GUIDES_PROPERTIES.maxRowsAvailableInPage;
      }
      const setContentParams: SetProductsContentInPageParams = {
        availableRowsInCurrentPage: availableRowsInCurrentPage,
        isMaxContentPerPageReached: isMaxContentPerPageReached,
        pageContent: pageContent,
        remissionGuidesParams: remissionGuidesParams,
        ...(!isBuildingDetailedContent && { isFirstPageFromOrder: isFirstPageFromOrder })
      };
      this.setProductsContentInCurrentPage(setContentParams);

      if (isBuildingDetailedContent) {
        remissionGuidesParams.orderContent.detailedContentByPages.push(pageContent);
      } else {
        remissionGuidesParams.orderContent.summarizedContentByPages.push(pageContent);
      }
      remissionGuidesParams.pageNumber++;
    } while (remissionGuidesParams?.currentOrder?.products?.length);

    if (!isBuildingDetailedContent) {
      remissionGuidesParams.allOrdersContent.push(remissionGuidesParams.orderContent);
    }
  }

  /**
   * @description Builds and sets products info in current page.
   * @param {SetProductsContentInPageParams} params - Params with neccesary info to build page content.
   */
  private setProductsContentInCurrentPage(params: SetProductsContentInPageParams) {
    do {
      const rowsRequiredForProductToAdd = params.pageContent.products?.length ? 1 :
        REMISSION_GUIDES_PROPERTIES.rowsQuantityRequiredForFirstProductInPage;

      if ((params.availableRowsInCurrentPage - rowsRequiredForProductToAdd) >= 0) {
        params.pageContent.products.push(params.remissionGuidesParams.currentOrder.products[0]);
        params.remissionGuidesParams.currentOrder.products.splice(0, 1);
        params.availableRowsInCurrentPage = params.availableRowsInCurrentPage - rowsRequiredForProductToAdd;
      } else {
        params.isMaxContentPerPageReached = true;

        if (params.isFirstPageFromOrder) {
          params.isFirstPageFromOrder = false;
        }
      }
    } while (!params.isMaxContentPerPageReached && params.remissionGuidesParams?.currentOrder?.products?.length);
  }

  /**
   * @description Checks and formats total volume provided to handle and display big quantities as compact version
   * or with comma for thousands.
   * @param {ProductsOrganized} productsData - Products info from order.
   * @returns {string} Total formatted as string.
   */
  private setTotalVolumenForPrintableFormat(productsData: ProductsOrganized): string {
    if (productsData?.totalProductsVolume && this.loadPlanService.isAGreatNumericValue(productsData?.totalProductsVolume)) {
      return this.loadPlanService.handleBigQuantities(productsData.totalProductsVolume);
    } else if (productsData?.totalProductsVolume && !this.loadPlanService.isAGreatNumericValue(productsData?.totalProductsVolume)) {
      return this.utilsService.formatNumberWithCommas(productsData.totalProductsVolume);
    }

    return AppConstants.ZERO_STRING;
  }
}
