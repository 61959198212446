import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConstants } from '../../../app/constants/app-constants.constants';
import { AppService } from '../../app.service';
import {
  ArrayOids, ErrorHandler, EvidenceResponse, MultipleSearchOrders, OrderGetResponse,
  OrderGuideUpdateMassiveRequest, OrderGuideUpdateMassiveResponse, OrderGuideUpdateRequest,
  OrderIdentifiersBody, OrderIdentifiersResponse, OrderPortageUpdate, OrderResponse,
  OrdersSearchBody, OrderUpdateRoadNetRequest, OrderWarehouseUpdate, PreshipmentOrderUpdate, PreshipmentOrderUpdateResponse,
  ResponseOrderApiInRouting, SearchedOrder, SearchOrderParams, ShipmentOrderUpdate,
  ShipmentRequest, Shipments, UpdateOrders, UpdateOrdersStatus, UpdateStatuAndShipment
} from '../../../app/interfaces';
import { ConfirmDeliveryAndRejectionParams } from 'src/app/interfaces/confirm-delivery';
import { environment } from '../../../environments/environment';
import {
  FileResponse,
  Orders,
  OrdersApi,
  SearchOrderResponse,
  SKUResponse,
  UccDataToSubmit
} from '../../interfaces/orders';
import { GetRemissionFoliosParams, GetRemissionGuidesResponse } from '../../interfaces/remission-guides';
import {
  GuidesSimplified, OrderApiLog, OrderDeliveredEvents, OrderInfo, OrderManualEvidence,
  OrderReleaseFolio, OrderResponseExtended, OrdersToStop, PostDatedInformation, UpdateOrderBody
} from '../../interfaces/order';
import { ImportationOrders } from '../../interfaces/importation-orders';
import { OrderCreationConfig, OrderRequestBody } from '../../interfaces/order-bulk-load';
import { OrderEvidenceDownloadResponse } from '../../../app/interfaces/evidenceDownload/orderEvidenceDownload';
import { OrderGeneralSearch } from '../../pages/orders/label-order-printing/interfaces/order-general-search.interface';
import {
  OrderViewsGuides,
  OrderGuideLogInterface,
  RejectionsLogResponse,
  RejectionsLog,
  UpdateStatusRejectedBodyRequest,
  UpdateStatusRejectedResponse
} from '../../interfaces/order-reception';
import { ParamsRequest } from '../../interfaces/status-changes';
import { RejectionReason } from '../../interfaces';
import { RequestToolsService } from '../../services/utils/request-tools.service';
import { WaybillOrder } from '../../interfaces/couriers/waybill-order.interface';


const apiUrl = environment.apiUrl;
const orderApiUrl = environment.orderApiUrl;

const DESTINATION_BY = {
  base: 'base',
  manual: 'manual'
};
const EMPTY_STRING = '';

@Injectable()
export class OrderProvider {

  constructor(
    private _appService: AppService,
    private http: HttpClient,
    private request: RequestToolsService
  ) { }

  /**
   * @description Build Product object
   * @param {any} row Excel row
   * @return {IImportationOrders['productos'][0]} Product object
   */
  public buildProduct(row: any): ImportationOrders['productos'][0] {
    const dangMaterialString = row.EsMaterialPeligroso ?? EMPTY_STRING;
    const isDangerousMaterial = AppConstants.AFFIRMATIVE_VALUES.includes(dangMaterialString.toLowerCase());
    const dangerousMaterialKey = isDangerousMaterial && row.ClaveDeMaterialPeligroso ?
      row.ClaveDeMaterialPeligroso : AppConstants.EMPTY_STRING;
    const packagingKey = isDangerousMaterial && row.ClaveDeEmbalaje ? row.ClaveDeEmbalaje : AppConstants.EMPTY_STRING;
    const packagingDesc = isDangerousMaterial && row.DescripcionDeEmbalaje ? row.DescripcionDeEmbalaje : AppConstants.EMPTY_STRING;
    const product: ImportationOrders['productos'][0] = {
      nombre: row.NombreProducto,
      codigo: row.CodigoProducto,
      total: row.Cantidad,
      unidad: row.Unidad,
      cmm: row.cmm,
      precio: row.Precio,
      row: row.__rowNum__,
      pesoProducto: row.PesoProducto,
      volumenProducto: row.VolumenProducto,
      claveDeUnidad: row.ClaveDeUnidad ?? AppConstants.EMPTY_STRING,
      claveDeProductoServicio: row.ClaveDeProductoServicio ?? AppConstants.EMPTY_STRING,
      esMaterialPeligroso: isDangerousMaterial,
      claveDeMaterialPeligroso: dangerousMaterialKey,
      claveDeEmbalaje: packagingKey,
      descripcionDeEmbalaje: packagingDesc,
      fraccionArancelaria: row.FraccionArancelaria ?? AppConstants.EMPTY_STRING,
      uuidComercioExterior: row.UuidComercioExterior ?? AppConstants.EMPTY_STRING
    };

    if (AppConstants.AFFIRMATIVE_VALUES.includes(row.isCofrepisProduct?.toLowerCase())) {
      product.isCofeprisProduct = AppConstants.AFFIRMATIVE_VALUES.includes(row.isCofrepisProduct?.toLowerCase());
      product.matterType = row.matterType;
      product.cofeprisSector = row.cofeprisSector;
      product.activeIngredientName = row.activeIngredientName;
      product.chemicalName = row.chemicalName;
      product.activeSubstanceGeneric = row.genericDenomination;
      product.trademarkMedication = row.distinctiveDenomination;
      product.manufacturer = row.manufacturer;
      product.expirationDate = row.expirationDate;
      product.lot = row.lot;
      product.pharmaceuticalForm = row.pharmaceuticalForm;
      product.transportSpecialConditions = row.specialTransportConditions;
      product.healthRegisterFolio = row.healthRegister;
      product['importLicense'] = row.importLicense;
      product.importVUCEMFolio = row.vucemFolio;
      product['numCAS'] = row.casNumber;
      product.importCompany = row.importingCompany;
      product.cofeprisPesticideNumber = row.pesticideHealthRegistrationNumber;
      product.pesticideManufacturerCountryName = row.manufacturerInfo;
      product.activeIngredientManufacturerCountryName = row.formulatorInfo;
      product.activeIngredientMaquilaCountryName = row.maquiladoraInfo;
      product.authorizedUse = row.authorizedUse;
    }

    return product;
  }

  /**
   * @description Send orders to create.
   * @param {Array<OrderRequestBody>} orders - Orders to create.
   * @param {OrderCreationConfig} config - Config to send for creation.
   */
  public async createOrders(orders: Array<OrderRequestBody>, config: OrderCreationConfig): Promise<void> {
    const body = {
      config: { isCreateProductByCatalog: config.isProductsByCatalog ?? undefined },
      data: orders
    };
    const shipperOid = this._appService.getShipperOid();
    const locationBy = config.isManualDestination ? DESTINATION_BY.manual : DESTINATION_BY.base;
    const url = `${orderApiUrl}/create?tenantId=${shipperOid}&locationBy=${locationBy}&shouldCreateAll=true`;

    await this.http.post<void>(url, body).toPromise();
  }

  public async newOrder(tenantId: string, orderData: any[], extended = false): Promise<OrderResponse | OrderResponseExtended> {
    const userName = this._appService.getShipperNameCookie();
    const url = extended ?
      `${orderApiUrl}/tenant/${tenantId}?user=${userName}&extended=${extended}` :
      `${orderApiUrl}/tenant/${tenantId}?user=${userName}`;

    return await this.http.post<OrderResponse | OrderResponseExtended>(url, orderData).toPromise();
  }

  public async getOrdersByShipment(shipmentReqId: string): Promise<Array<ShipmentRequest>> {
    const shipperOid = this._appService.getShipperOid();
    const userOid = this._appService.getUserOid();
    const url = `${apiUrl}/solicitudes/${shipmentReqId}/evidencias?embarcador=${shipperOid}&userOid=${userOid}`;
    return this.http.get<Array<ShipmentRequest>>(url).toPromise();
  }

  public async getOrderByName(orderName: string): Promise<SearchedOrder> {
    const shipperOid = this._appService.getShipperOid();
    return this.http.get<SearchedOrder>
      (apiUrl + '/embarcadores/' + shipperOid + '/documentosalida?nombre=' + orderName + '&limit=100&skip=0')
      .toPromise();
  }

  public async getOrdersByParams(tenantId: string, body: OrdersSearchBody): Promise<object> {
    body.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    body.utcOffset = new Date().getTimezoneOffset();
    return await this.http.post<object>(orderApiUrl + '/tenant/' + tenantId + '/search', body).toPromise();
  }

  public async getOrdersBySearchParams(tenantId: string, body): Promise<SearchOrderResponse> {
    body.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    body.utcOffset = new Date().getTimezoneOffset();
    return await this.http.post<SearchOrderResponse>(orderApiUrl + '/tenant/' + tenantId + '/searchOrders', body).toPromise();
  }

  public async updateStatusToRelease(ordersData): Promise<object> {
    const tenantId = this._appService.getShipperOid();
    return await this.http.put<object>(orderApiUrl + '/tenant/' + tenantId + '/release', ordersData).toPromise();
  }

  public async updateOrderShipment(user: string, body: ShipmentOrderUpdate, isAssign: boolean): Promise<Object> {
    return await this.http.put(orderApiUrl + '/shipment/orders?user=' + user + '&isAssign=' + isAssign, body).toPromise();
  }

  public async updateOrder(tenantId: string, _id: string, infoOrderUpdate: object): Promise<object> {
    const userName = this._appService.getShipperNameCookie();
    return await this.http.put<object>(orderApiUrl + '/tenant/' + tenantId + '/order/' + _id + '?user=' + userName, infoOrderUpdate)
      .toPromise();
  }

  public async createMassiveOrders(tenantId: string, ordersData: object): Promise<OrderResponse> {
    const userName = this._appService.getShipperNameCookie();
    return await this.http.post<OrderResponse>(orderApiUrl + '/tenant/' + tenantId + '?user=' + userName, ordersData).toPromise();
  }

  public async getOrders(ordersId: Object): Promise<Orders> {
    return this.http.post<Orders>(apiUrl + '/orders/byOids', ordersId).toPromise();
  }

  public async getOrderByOids(ordersId: ArrayOids): Promise<OrdersApi> {
    const tenantId = this._appService.getShipperOid();
    return this.http.post<OrdersApi>(orderApiUrl + '/tenant/' + tenantId + '/byOids', ordersId).toPromise();
  }

  /**
   * @description Gets orders for evidence download
   * @param tenantId shipper id
   * @param account account name
   * @param beginDate account name
   * @param endDate account name
   * @returns Matched orders
   */
  public async getOrdersForEvidenceDownload(body: OrdersSearchBody, tenantId: string): Promise<OrderEvidenceDownloadResponse> {
    return this.http.post<OrderEvidenceDownloadResponse>(orderApiUrl + '/tenant/' + tenantId + '/forEvidenceDownload', body).toPromise();
  }

  /**
   * @description Gets orders logs for evidence download
   * @param orderIds Array of orders Ids
   * @returns Matched orders logs
   */
  public async getOrdersLogsForEvidenceDownload(orderIds: Array<string>): Promise<object> {
    return this.http.post<object>(orderApiUrl + '/order/orderlogs/forEvidenceDownload', orderIds).toPromise();
  }

  /**
   * @description it retrieves an array of orders found by the ObjectId of these
   * @param ordersId Oid of the orders to get
   */
  public async getOrdersByOids(ordersId: ArrayOids): Promise<OrdersApi[]> {
    const tenantId = this._appService.getShipperOid();
    return this.http.post<Array<OrdersApi>>(orderApiUrl + '/tenant/' + tenantId + '/byOids', ordersId).toPromise();
  }

  public async getRejectios(rejectionOId: Object): Promise<Array<RejectionReason>> {
    return this.http.get<Array<RejectionReason>>(apiUrl + '/embarcadores/' + rejectionOId + '/motivosRechazo', rejectionOId).toPromise();
  }

  public async getOrdersByShipperAndParams(tenantId: string, body: any): Promise<OrderGetResponse> {
    body.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    body.utcOffset = new Date().getTimezoneOffset();
    return this.http.get<OrderGetResponse>(orderApiUrl + '/tenant/' + tenantId + '/order', { params: body }).toPromise();
  }

  public async updateOrderStatus(tenantId: string, body: UpdateOrdersStatus): Promise<any> {
    return await this.http.put<object>(orderApiUrl + '/tenant/' + tenantId + '/status', body)
      .toPromise();
  }

  /**
   * @description Generate request for update orders with status specific
   * @param {string} tenantId tenant or shipperId for updated status
   * @param {UpdateStatusRejectedBodyRequest} body of the orders to updated
   */
  public async updateOrderStatusRejected(tenantId: string, body: UpdateStatusRejectedBodyRequest): Promise<UpdateStatusRejectedResponse> {
    return await this.http.put<UpdateStatusRejectedResponse>(orderApiUrl + '/tenant/' + tenantId + '/status/rejected', body)
      .toPromise();
  }

  public async updateOrderShipmentStatus(tenantId: string, body: UpdateStatuAndShipment): Promise<any> {
    return this.http.put<any>(orderApiUrl + '/tenant/' + tenantId + '/shipment' + '/status', body).toPromise();
  }

  public async getShipmentById(shipmentId: string): Promise<Array<ShipmentRequest>> {
    const shipperOid = this._appService.getShipperOid();
    return await this.http.get<Array<ShipmentRequest>>(`${apiUrl}/solicitudes/${shipmentId}/evidencias?embarcador=${shipperOid}`)
      .toPromise();
  }

  public async getOrdersByShipperAndAccounts(tenantId: string, params: SearchOrderParams, account: Array<string>
  ): Promise<OrderGetResponse> {
    let url = `${orderApiUrl}/tenant/${tenantId}/order`;
    params.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    params.utcOffset = new Date().getTimezoneOffset();

    if (params.isInsensitive) {
      url += '?isInsensitive=' + params.isInsensitive;
    }

    return this.http.post<OrderGetResponse>(url, { params: params, account: account }).toPromise();
  }

  /**
   * @description Import orders
   * @param {IImportationOrders[]} orders Orders to be imported
   * @param {string} shipperOid Shipper's orders
   */
  public async importOrders(orders: Array<ImportationOrders>, shipperOid: string): Promise<any> {
    const payload = { orders: orders };
    return this.http.post(apiUrl + '/orders/' + shipperOid + '/importation', payload).toPromise();
  }

  /**
   * @description Update the order's manualDelivered status
   * @param body The body request necessary to update includes ordersIds and manualDelivered status
   */
  public async updateManualDelivered(body: object): Promise<Object> {
    const tenantId = this._appService.getShipperOid();
    const username = this._appService.getShipperNameCookie();
    return this.http.patch(`${orderApiUrl}/tenant/${tenantId}/byOids?user=${username}`, body).toPromise();
  }

  public async updateOrderWarehouse(tenantId: string, updateBody: OrderWarehouseUpdate): Promise<Object> {
    const userName = this._appService.getShipperNameCookie();
    return await this.http.patch<Object>(`${orderApiUrl}/tenant/${tenantId}/warehouse?user=${userName}`, updateBody).toPromise();
  }

  public async orderReceptionUpdate(tenantId: string, orderOid: string, updateBody: OrderPortageUpdate): Promise<Object> {
    const userName = this._appService.getShipperNameCookie();
    return await this.http.put<Object>
      (`${orderApiUrl}/tenant/${tenantId}/order/${orderOid}?user=${userName}&portageReception=${true}`, updateBody).toPromise();
  }

  /**
   * @description Generate request for create RejectionsLog to order
   * @param {string} shipperOid shipper or tenant related to order
   * @param {string} orderOid id of order
   * @param {string} username of usert in the session
   * @param {RejectionsLog} rejection object to create
   * @param {Promise<RejectionsLogResponse>} response of creation
   */
  public async createRejection(
    shipperOid: string,
    orderOid: string,
    username: string,
    rejection: RejectionsLog): Promise<RejectionsLogResponse> {
    shipperOid = shipperOid ?? this._appService.getShipperOid();
    return await this.http.post<RejectionsLogResponse>(
      `${orderApiUrl}/shippers/${shipperOid}/orders/${orderOid}/rejections?username=${username}`, rejection
    ).toPromise();
  }

  public async getOrderLog(orderOid: string): Promise<OrderApiLog> {
    return this.http.get<OrderApiLog>(`${orderApiUrl}/order/${orderOid}/logs`).toPromise();
  }

  /**
   * @description Update the order's inRouting status
   * @param body The body request necessary to update includes ordersIds and inRouting status
   */
  public async updateOrderInRoutingStatus(body: object): Promise<ResponseOrderApiInRouting> {
    const tenantId = this._appService.getShipperOid();
    const username = this._appService.getShipperNameCookie();
    return this.http.patch<ResponseOrderApiInRouting>(`${orderApiUrl}/tenant/${tenantId}/order/routing?user=${username}`, body).toPromise();
  }

  /**
   * @description Update the order's inRouting deliverydate and appointmentHour
   * @param body The body request necessary to update includes ordersIds and inRouting status
   * @returns {Promise<ResponseOrderApiInRouting>} File Response body
   */
  public async updateOrderRoadnet(body: OrderUpdateRoadNetRequest): Promise<ResponseOrderApiInRouting> {
    const tenantId = this._appService.getShipperOid();
    const username = this._appService.getShipperNameCookie();
    return this.http.put<ResponseOrderApiInRouting>(
      `${orderApiUrl}/shipper/${tenantId}/orders/ordersRoadnet?user=${username}`, body
    ).toPromise();
  }

  /**
   * @description Get signature file
   * @param fileOid Signature file Object Id
   * @returns {FileResponse} File Response body
   */
  public async getFile(fileOid: string): Promise<FileResponse> {
    return this.http.get<FileResponse>(`${apiUrl}/archivos/${fileOid}`).toPromise();
  }

  /**
   * @description Get orders based on identifiers
   * @param bodyReq The body with the identifiers to find
   * @returns {Promise<OrderIdentifiersResponse>} A promise with the orders found
   */
  public async getOrdersByIdentifiers(bodyReq: OrderIdentifiersBody): Promise<OrderIdentifiersResponse> {
    const tenantId = this._appService.getShipperOid();
    return this.http.post<OrderIdentifiersResponse>(`${orderApiUrl}/tenant/${tenantId}/identifiers`, bodyReq).toPromise();
  }

  /**
   * @description update several orders by identifiers
   * @param body an array with all info to be submited in the orders
   * @returns {Promise<SKUResponse>} A promise with response from server
   */
  public async updateManyOrdersByIdentifiers(body: Array<object>): Promise<SKUResponse> {
    const tenantId = this._appService.getShipperOid();
    return this.http.put<SKUResponse>(`${orderApiUrl}/tenant/${tenantId}/identifiers`, body).toPromise();
  }

  public async getOrdersPreshipment(tenantId: string, warehouseId: string): Promise<OrderGetResponse> {
    return this.http.get<OrderGetResponse>(`${orderApiUrl}/tenant/${tenantId}/order/preshipment?warehouse=${warehouseId}`).toPromise();
  }

  public async updateOrdersPreshipment(
    tenantId: string,
    user: string,
    isAssign: boolean,
    preshipment: PreshipmentOrderUpdate): Promise<PreshipmentOrderUpdateResponse> {
    return this.http.patch<PreshipmentOrderUpdateResponse>
      (`${orderApiUrl}/tenant/${tenantId}/order/preshipment?isAssign=${isAssign}&user=${user}`, preshipment).toPromise();
  }

  /**
   * @description Update the volume of each detail of the order
   * @param requestDetailOrder Volumetry and subfolio of the order detail
   */
  public updateGuide(requestDetailOrder: OrderGuideUpdateRequest): Observable<GuidesSimplified> {
    return this.http.put<GuidesSimplified>(`${orderApiUrl}/update-volumetrics-guide`, requestDetailOrder);
  }

  /**
   * @description Update the volume of each detail of the order in  bulk
   * @param requestDetailMassiveOrder Volumetry and array of subfolio of the order detail
   * @returns {Observable<OrderGuideUpdateMassiveResponse>}
   */
  public updateGuideMassive(requestDetailMassiveOrder: OrderGuideUpdateMassiveRequest): Observable<OrderGuideUpdateMassiveResponse> {
    return this.http.put<OrderGuideUpdateMassiveResponse>(`${orderApiUrl}/update-volumetrics-guide-massive`, requestDetailMassiveOrder);
  }

  /**
   * @description Gets the delivered date from event log for all the orderOIds given
   * @param {Array<string>} orderOIds Order OId's to search for
   * @returns {Promise<OrderDeliveredEvents>} Order delivered events for all the found orders
   */
  public async getDeliveredOrderEventLog(orderOIds: Array<string>): Promise<OrderDeliveredEvents> {
    return this.http.post<OrderDeliveredEvents>
      (`${orderApiUrl}/order/delivered/logs`, { ordersIds: orderOIds }).toPromise();
  }

  /**
   * @description Set manualEvidences at order's properties
   * @param {OrderManualEvidence} manualEvidenceBody Orders and evidence info to be updated
   * @returns {Promise<EvidenceResponse>} Document updated at database
   */
  public async setManualEvidences(manualEvidenceBody: OrderManualEvidence): Promise<EvidenceResponse> {
    return await this.http.put<EvidenceResponse>(`${orderApiUrl}/manualEvidences`, manualEvidenceBody).toPromise();
  }

  /**
   * @description Set release folio at order's properties
   * @param {OrderReleaseFolio} releaseBody Orders and release folio info to be updated
   */
  public async addedReleaseFolio(releaseBody: OrderReleaseFolio): Promise<void> {
    return await this.http.patch<void>(`${orderApiUrl}/releaseFolio`, releaseBody).toPromise();
  }

  /**
    * @description Update Evidence Status
    * @param {object} updateBody Object necessary for make the update
    * @returns {Promise<object>} Returns an object of the update status
    */
  public async updateEvidenceStatus(updateBody: object): Promise<object> {
    const tenantId = this._appService.getShipperOid();
    return await this.http.patch<object>
      (`${orderApiUrl}/tenant/${tenantId}/manualEvidencesRelease`, updateBody).toPromise();
  }

  /**
   * @description Find the order by the guide provided
   * @param {string} guide The guide provided
   * @returns {Promise<OrderInfo>} Returns the information of the order
   */
  public async findOrderByGuide(guide: string): Promise<OrderInfo> {
    const tenantId = this._appService.getShipperOid();
    return this.http.get<OrderInfo>(`${orderApiUrl}/tenant/${tenantId}/guide/${guide}`).toPromise();
  }


  /**
   * @description Gets an order by UCC
   * @param {string} ucc UCC of an order to found
   * @returns {OrderInfo} Order found
   */
  public async findOrderByUcc(ucc: string): Promise<OrderInfo> {
    const tenantId = this._appService.getShipperOid();
    return this.http.get<OrderInfo>(`${orderApiUrl}/tenant/${tenantId}/ucc/${ucc}`).toPromise();
  }
  /**
   * @description Gets orders with all properties of guides
   * @param {string} tenantId The client identifier
   * @param {SearchOrderParams} params The request data
   * @param {Array<string>} accounts The accounts selected
   * @returns {Promise<OrderGetResponse>} An orders with guides
   */
  public async getOrdersWithGuideInfo(tenantId: string, params: SearchOrderParams, accounts: Array<string>): Promise<OrderGetResponse> {
    params.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    params.utcOffset = new Date().getTimezoneOffset();
    const url = `${orderApiUrl}/order/tenant/${tenantId}/with-guide-info`;
    return this.http.post<OrderGetResponse>(url, { params: params, account: accounts }).toPromise();
  }

  /**
   * @description Updates orders with url waybills
   * @param {string} tenantId The client identifier
   */
  public updateWaybillsOrders(tenantId: string, courierOrders: Array<WaybillOrder>): Promise<void> {
    const url = `${orderApiUrl}/order/tenant/${tenantId}/update/waybills`;
    return this.http.post<void>(url, courierOrders).toPromise();
  }

  /**
   * @description Get orders by waybills
   * @param orderGeneralSearch orderGeneralSearch, Array Orders Api
   * @returns {Promise<Array<OrdersApi>>} Returns an array of orders api
   */
  public async getOrdersbyWaybills(orderGeneralSearch: OrderGeneralSearch): Promise<Array<OrdersApi>> {
    const tenantId = this._appService.getShipperOid();
    orderGeneralSearch.utcOffset = new Date().getTimezoneOffset();
    return await this.http.post<Array<OrdersApi>>
      (`${orderApiUrl}/waybills/find/tenant/${tenantId}`, orderGeneralSearch).toPromise();
  }

  /**
   * @description Update the printed waybill
   * @param folios Array string
   */
  public async updateIsPrintedWaybills(folios: Array<string>): Promise<void> {
    const tenantId = this._appService.getShipperOid();
    return await this.http.put<void>
      (`${orderApiUrl}/waybills/update/is-printed-waybills/shipperOid/${tenantId}`, folios).toPromise();
  }

  /**
   * @description Get Orders Waybills by Multiple Inputs
   * @param searchOrderParams searchOrderParams
   * @returns {Promise<Array<OrdersApi>>}
   */
  public async getOrdersWaybillsByMultipleInputs(searchOrderParams: SearchOrderParams): Promise<Array<OrdersApi>> {
    const tenantId = this._appService.getShipperOid();
    return await this.http.post<Array<OrdersApi>>
      (`${orderApiUrl}/waybills/find/multiple-inputs/tenant/${tenantId}`, searchOrderParams).toPromise();
  }

  /**
   * @description generates and shares the xml files for the orders to SAP
   * @param {Array<OrdersApi>} orders order's data to generate the XML file
   * @param {Shipments} shipmendata data from the shipment of orders
   * @param {string} evidenceDate time that the evidence is make it
   * @param {Array<string>} fileNames timestamps generated for the orders
   * @returns {object} response of enpdoint
   */
  public async generateAndShareDeliveryDate(orders: Array<OrdersApi>, shipmendata: Shipments,
    evidenceDate: string, fileNames: Array<string>): Promise<object> {
    return await this.http.post<object>(`${apiUrl}/orders/shareDeliveryDate`,
      { orders: orders, shipment: shipmendata, evidenceDate: evidenceDate, fileNames: fileNames }).toPromise();
  }

  public async submitMassiveRejections(rejections): Promise<any> {
    const shipperOid = this._appService.getShipperOid();
    const username = this._appService.getShipperNameCookie();
    const url = orderApiUrl + '/shipperOid/' + shipperOid + '/massiveRejections?user=' + username;
    const chunkSize = 250;
    const response = await this.request.doPOSTRequestByBatch(url, rejections, chunkSize);

    return response;
  }

  /**
   * @description Submits Order's UCCs to db
   * @param {UccDataToSubmit} bodyReq Orders sith UCCs and with or without guides to submit on db
   * @returns {object} Endpoint's response
   */
  public async submitOrdersUccs(bodyReq: UccDataToSubmit): Promise<object> {
    const tenantId = this._appService.getShipperOid();
    return this.http.post<UccDataToSubmit>(`${orderApiUrl}/tenant/${tenantId}/UCCs`, bodyReq).toPromise();
  }

  /**
   * @description Update order's stopped flag
   * @param ordersStopped Orders to stop
   */
  public async updateStoppedOrders(ordersStopped: OrdersToStop): Promise<void> {
    const tenantId = this._appService.getShipperOid();
    return await this.http.put<void>
      (`${orderApiUrl}/tenant/${tenantId}/stopped`, ordersStopped).toPromise();
  }

  /**
   * @description Gets guides by folio
   * @param {string} folio folio for the search of guides
   * @returns {Array<OrderViewsGuides>} Array of guides by folio
   */
  public async getGuidesByFolio(folio: string): Promise<Array<OrderViewsGuides>> {
    return this.http.get<Array<OrderViewsGuides>>(orderApiUrl + '/folio/' + folio + '/getGuidesByFolio').toPromise();
  }

  /**
   * @description Gets guides by folio
   * @param {string} subfolio subfolio for the search of guides
   * @returns {Array<OrderGuideLogEvent>} Array of guides by folio
   */
  public async getGuideLogsBySubfolio(subfolio: string): Promise<OrderGuideLogInterface> {
    return this.http.get<OrderGuideLogInterface>(orderApiUrl + '/subfolio/' + subfolio + '/getGuideLogs').toPromise();
  }

  /* @description Search an specific order by Folio
   * @param folio Search param
   * @returns An order
   */
  public async getOrderByFolio(account: string, folio: string): Promise<Array<OrderInfo>> {
    return await this.http.get<Array<OrderInfo>>(`${orderApiUrl}/account/${account}/folio/${folio}/getOrdersByFolio`).toPromise();
  }

  /**
   * @description Gets multiples orders by a specific param
   * @param {MultipleSearchOrders} body Search params for orders
   * @returns {Array<OrderInfo>} Orders found by the given criteria
   */
  public async getMultipleOrdersByParam(body: MultipleSearchOrders): Promise<Array<OrderInfo>> {
    const shipperOid = this._appService.getShipperOid();
    return await this.http.post<Array<OrderInfo>>(`${orderApiUrl}/shipper/${shipperOid}/orders/params`, body).toPromise();
  }

  /**
   * @description Updates multiple orders.
   * @param {UpdateOrders} body - Orders update data array and action to apply to them.
   * @param {string} userName - User name of who is updating the orders.
   * @returns {Promise<ErrorHandler>} Error handler response in case update fails.
   */
  public async updateMultipleOrders(body: UpdateOrderBody, userName: string): Promise<ErrorHandler> {
    const shipperOid = this._appService.getShipperOid();

    return await this.http.put<Promise<ErrorHandler>>(`${orderApiUrl}/shipper/${shipperOid}/orders?user=${userName}`, body).toPromise();
  }

  /**
   * @description Updates multiples orders properties by query previously sent.
   * @param {UpdateOrders} body - Orders identifiers and query to update properties.
   * @returns {OrderResponse} A response about how manyu orders have updated successfully.
   */
  public async updateMultipleOrdersByParam(body: UpdateOrders): Promise<OrderResponse> {
    const shipperOid = this._appService.getShipperOid();

    return await this.http.put<OrderResponse>(`${orderApiUrl}/shipper/${shipperOid}/ordersParams`, body).toPromise();
  }

  /**
   * @description Unassings a selected order from shipment.
   * @param {string} shipperOid - Current shipper Object ID.
   * @param {string} orderOid - Order Object ID to unassign.
   * @param {PostDatedInformation} body - Postadated information to save.
   * @param {string} shipmentId - Shipment order to unassign.
   * @returns {OrderResponse} Order object updated and success messagge.
   */
  public async unassignOrdersFromShipment(
    shipperOid: string,
    orderOid: string,
    body: PostDatedInformation,
    shipmentId?: string,
  ): Promise<Orders> {
    return await this.http.put<Orders>(`${orderApiUrl}/shipper/${shipperOid}/order/${orderOid}/shipment/${shipmentId}/postDated`,
      body).toPromise();
  }

  /**
   * @description Confirms delivery and rejection from shipment. Sending a request to update order status.
   * @param {ConfirmDeliveryAndRejectionParams} params - Contains params to make the request.
   * @returns {Promise<OrderResponse>} Response of request.
   */
  public async confirmDeliveryAndRejections(params: ConfirmDeliveryAndRejectionParams): Promise<OrderResponse> {
    const shipmentId = params.shipmentId;
    const shipmentRequestId = params.shipmentRequestId;
    const tenantOId = params.shipperOId;
    const url = `${orderApiUrl}/confirmDeliveriesAndRejections/` +
      `tenantId/${tenantOId}/shipment/${shipmentId}/shipmentRequest/${shipmentRequestId}`;
    return await this.http.put<OrderResponse>(url, params).toPromise();
  }

  /**
   * @description Change order status as a support movement.
   * @param {string} shipperOid - Current shipper/tenant object identifier.
   * @param { ParamsRequest } paramsRequest - Params to update order status.
   */
  public async changeOrderStatusBySupport(shipperOid: string, paramsRequest: ParamsRequest): Promise<any> {
    let url = `${orderApiUrl}/shipper/${shipperOid}/order/${paramsRequest.orderOid}/supportMovement?`
    + `from=${paramsRequest.currentStatus}&to=${paramsRequest.desirableStatus}&isHttpRequest=${true}`;

    if (paramsRequest.supportTicket) {
      url += `&supportTicket=${paramsRequest.supportTicket}`;
    }

    return await this.http.put<any>(url, paramsRequest).toPromise();
  }
}
