/**
 * @description Available status can belong a remission guide after receive response from being sent to authorization.
 */
export enum RemissionGuidesAuthorizationStatus {
  authorizated = 'authorizated',
  inValidation = 'inValidation',
  noGenerated = 'noGenerated',
  rejected = 'rejected',
  returned = 'returned'
}
